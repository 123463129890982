import { FC, forwardRef } from "react";
import { ButtonProps } from "./Button.type";
import { StyledButton, StyledIcon } from "./Button.styled";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
    children,
    variant = "primary",
    size = "medium",
    disabled = false,
    onClick,
    icon,
    className,
    center = false,
    onKeyDown,
    onMouseEnter,
    onMouseLeave
}, ref) => {

    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled) return;
        onClick(e);
    };
    return (
        <StyledButton
            onKeyDown={onKeyDown}
            center={center}
            hasChildren={!!children}
            variant={variant}
            size={size}
            disabled={disabled}
            className={className}
            onClick={onClickHandler}
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {!!icon && <StyledIcon hasChildren={!!children} variant={variant} disabled={disabled}>{icon}</StyledIcon>}
            {children}
        </StyledButton>
    )
});