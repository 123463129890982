import { FC, useMemo } from "react";
import { PersonProps } from "./Person.type";
import { StyledUserAvatar } from "./Person.styled";
import { getFullName } from "@/utils/getFullName";
import { ListItem } from "../ListItem/ListItem.component";

export const Person: FC<PersonProps> = ({ person, onClick, className, caption }) => {
    const fullName = useMemo(() => {
        return  getFullName(person);
    }, [person]);
    return <ListItem
        title={fullName}
        caption={caption ? caption : person.email}
        onClick={onClick}
        img={<StyledUserAvatar avatar={person.avatar} size="large"/>}
        className={className}
        useSkeleton
    />
};