import { ComponentType } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ChromeExtensionPage } from '../pages/ChromeExtension/ChromeExtension.page';
import { OnboardingConnection } from "../components/ConnectChannels/OnboardingConnection";
import { SelectPlan } from "../pages/SelectPlan/SelectPlan.page";
import { NodeNeighboursPage } from '@/pages/v3/NodeNeighbours/NodeNeighbours.page';
import { ManageIntegrations } from '@/pages/v3/ManageIntegrations/ManageIntegrations.page';
import { InviteTeammates } from '@/pages/v3/InviteTeammates/InviteTeammates.page';

export type ComponentRouteConfig = {
  path: string;
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  exact?: boolean;
};

export const mainLayoutRoutesConfig: ComponentRouteConfig[] = [
  // {
  //   path: '/recent-activities',
  //   component: RecentActivitiesNodes,
  // },
  {
    path: '/public/node/:code',
    component: NodeNeighboursPage,
  },
  {
    path: '/space/:spaceId/node-neighbours/:nodeId',
    component: NodeNeighboursPage,
  },
];

export const onboardingLayoutRouteConfig: ComponentRouteConfig[] = [
  {
    path: '/manage-integrations',
    component: ManageIntegrations,
  },
  {
    path: '/chrome-extension',
    component: ChromeExtensionPage,
  },
  {
    path: '/invite',
    component: InviteTeammates,
  },
  {
    path: '/connect-slack-channels',
    component: OnboardingConnection,
  },
  {
    path: '/connect-click-up-projects',
    component: OnboardingConnection,
  },
  {
    path: '/connect-trello-boards',
    component: OnboardingConnection,
  },
  {
    path: '/connect-asana-projects',
    component: OnboardingConnection,
  },
  {
    path: '/connect-github-projects',
    component: OnboardingConnection,
  },
  {
    path: '/connect-atlassian-projects',
    component: OnboardingConnection,
  },
  {
    path: '/connect-microsoft-teams-projects',
    component: OnboardingConnection,
  },
  {
    path: '/outlook',
    component: OnboardingConnection,
  },
  {
    path: '/gmail',
    component: OnboardingConnection,
  },
  {
    path: '/select-plan',
    component: SelectPlan,
  },
];

// export const routes: LayoutRouteConfig[] = [
//   {
//     layout: MainContentLayout,
//     public: false,
//     routes: [...mainLayoutRoutesConfig],
//   },
// ];

// export type LayoutRouteConfig = {
//   layout: any;
//   public?: boolean;
//   routes: ComponentRouteConfig[];
// };
