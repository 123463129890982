import { FC } from "react";
import { StyledTab, StyledTabs, StyledWrapper } from "./Tabs.styled";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { TabValueType } from "@/providers/v3/SpacePage/SpacePage.type";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";

export const Tabs: FC = () => {
  const { activeTab, setActiveTab } = useSpacePage();
  const shouldRenderTabsFromSidebar = useRenederBelow('medium');
  return <StyledWrapper>
    <StyledTabs value={activeTab} onChange={(newVal) => setActiveTab(newVal as TabValueType)}>
      <StyledTab value="UPDATES">Updates</StyledTab>
      <StyledTab value="ALL_RESOURCES">All resources</StyledTab>
      {/* <StyledTab value="EMAILS">Emails</StyledTab> */}
      {/* <StyledTab value="MENTIONS">Mentions</StyledTab> will be returned in the feature */}
      <StyledTab value="ACTIVITIES">Activities</StyledTab>
      {shouldRenderTabsFromSidebar && <>
        {/* <StyledTab value="KEY_RESOURCES">Key resources</StyledTab> */}
        <StyledTab value="CHANNELS">Channels</StyledTab>
        <StyledTab value="PEOPLE">People</StyledTab>
      </>}
    </StyledTabs>
  </StyledWrapper>
};