import { Box, FormControl, Typography, styled } from "@mui/material";
import { ApplicationIcon } from "../ApplicationIcon/ApplicationIcon.component";
import { Button } from "../v3/Fields/Button/Button.component";

export const StyledApplicationIcon = styled(ApplicationIcon)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`;
export const StyledTitleContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
export const StyledDescriptionTitle = styled('div')`
    margin-bottom: 16px;
    color: #202020;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
export const StyledDescription = styled('div')`
    margin-bottom: 16px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
export const StyledFormControl = styled(FormControl)`
    height: 350px;
    overflow-y: scroll;
`;
export const StyledCancelButton = styled(Button)`
    margin-right: 8px;
`;