import { FC, useEffect, useState } from "react";
import { StyledSectionForm } from "./SectionForm.styled";
import { StyledCard, StyledInput, StyledTitle, StyledButton, StyledSubmitButton, StyledDivider } from "@/pages/v3/Login/Login.styled";
import { GoogleLoginButton } from "@/pages/v3/Login/components/GoogleLoginButton/GoogleLoginButton.component";
import { ErrorsType } from "./SectionForm.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { deleteCookie, getCookie } from "@/helpers/cookies";
import { OrganozationsCreateFetch } from "@/axios/OrganizationService/Organizations/Organizations.api";
import { ToastService } from "@/service/ToastService";
import { LoginFetch } from "@/axios/OrganizationService/Users/Users.api";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { LoginWithoutOTPResponseSuccessType } from "@/axios/OrganizationService/Users/Types/Login/Login.type";
import { AxiosResponse } from "axios";
import { OrganozationCreateBodyParams } from "@/axios/OrganizationService/Organizations/Types/Create/Create.type";
import { Terms } from "@/pages/v3/Login/components/Terms/Terms.component";
import { useMain } from "@/providers/MainProvider/Main.provider";
import useCopyToClipboard from "@/hooks/UseCopyToClipboard/UseCopyToClipboard.hook";

export const SectionForm: FC = () => {
    const [fullName, setFullName] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rePassword, setRePassword] = useState<string>('');
    const [errors, setErrors] = useState<ErrorsType>({});
    const router = useRouter();
    const { login } = useMe();
    const { env } = useMain();
    const { copyToClipboard } = useCopyToClipboard();
    // autocomplete for developers to test
    useEffect(() => {
        // @ts-ignore
        window.autocomplete = function() {
            const mailer = env==='local' ? 'gmail.com' : 'senseapp.ai';
            const emailPromt = prompt('Enter email');
            if (emailPromt) {
                let email = emailPromt;
                if (!email.includes('@')) {
                    if (email.includes('+')) {
                        email = `${email}@${mailer}`;
                    } else {
                        // generate random suffix with characters and numbers
                        const randomSuffix = Math.random().toString(36).substring(2, 8);
                        email = email + `+${randomSuffix}@${mailer}`;
                    }
                }
                setEmail(email);
                setFullName('Sense Test');
                setCompanyName('Sense Test');
                setPassword(email);
                setRePassword(email);
                copyToClipboard(email, 'Email/Pass was copied to clipboard');
            }
        }
    }, [copyToClipboard, env]);
    // end autocomplete for developers to test
    const COUPON = 'couponCode'
    const couponCode = getCookie(COUPON);
    const validate = () => {
        let tempErrors: ErrorsType = {};
    
        if (!fullName) tempErrors.fullName = "Full name is required";
    
        if (!companyName) tempErrors.companyName = "Company name is required";
    
        if (!email) tempErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(email)) tempErrors.email = "Invalid email format";
    
        if (!password) tempErrors.password = "Password is required";
        else if (password.length < 8) tempErrors.password = "Password is too short - should be 8 chars minimum";
        else if (!/\d/.test(password)) tempErrors.password = "Password must contain at least one number character";
        else if (!/[!@#$%^&*]/.test(password)) tempErrors.password = "Password must contain at least one special character";
    
        if (!rePassword) tempErrors.rePassword = "Please confirm your password";
        else if (rePassword !== password) tempErrors.rePassword = "Passwords must match";
    
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    
    const submit = () => {
        if (validate()) {
            const createUserRequest: OrganozationCreateBodyParams = {
                name: companyName,
                admin: {
                  email: email,
                  fullName: fullName,
                  password: password,
                },
                couponCode: couponCode ? couponCode : undefined
              }
              OrganozationsCreateFetch(createUserRequest).then((response) => {
                ToastService.showToast('success', 'User successfully created');
                deleteCookie(COUPON);
                LoginFetch({
                    email: email,
                    password: password,
                }).then((authResponse: AxiosResponse<LoginWithoutOTPResponseSuccessType>) => {
                    login(authResponse.data.accessToken, () => {
                        router.push({
                            name: 'linkConfirmation'
                        })
                    });
                })
                .catch(error => {
                  ToastService.showToast('error', error?.response?.data?.errorMessage);
                });
              })
              .catch(error => {
                ToastService.showToast('error', error?.response?.data?.errorMessage);
              });
        };
    };
    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            submit();
        }
    };
    return <StyledSectionForm>
        <div>
            <StyledTitle>Create Sense account</StyledTitle>
            <StyledCard>
                <GoogleLoginButton />
                <div><StyledInput error={errors.fullName} value={fullName} onChange={(e) => setFullName(e.target.value)} label="Full name" /></div>
                <div><StyledInput error={errors.companyName} value={companyName} onChange={(e) => setCompanyName(e.target.value)} label="Company name" /></div>
                <div><StyledInput type="email" error={errors.email} value={email} onChange={(e) => setEmail(e.target.value)} label="Email" /></div>
                <div><StyledInput error={errors.password} value={password} onChange={(e) => setPassword(e.target.value)} label="Password" type="password" /></div>
                <div><StyledInput error={errors.rePassword} value={rePassword} onChange={(e) => setRePassword(e.target.value)} label="Repeat password" type="password" /></div>
                <StyledSubmitButton size="large" onClick={submit}>Create account</StyledSubmitButton>
                <StyledDivider />
                <StyledButton onKeyDown={onKeyDownHandler} size="large" onClick={() => router.push({
                    name: 'login'
                })} variant="secondary">Login</StyledButton>
            </StyledCard>
        </div>
        <Terms />
    </StyledSectionForm>
};