import React from 'react';
import { useMe } from 'providers/MeProvider/Me.provider';
import { EventTypes, useAmplitude } from "service/TrackingService";
import { SearchResultProps } from './SearchResultView.type';
import { useNode } from 'providers/v3/NodeProvider/Node.provider';
import { StyledNode, StyledPerson, StyledWrapper } from "./SearchResultView.styled";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useRouter } from "@/providers/Router/Router.provider";
import { GroupLayout } from "@/components/v3/Layouts/GroupLayout/GroupLayout.component";

export const SearchResultView: React.FC<SearchResultProps> = ({
  listOfNodes,
  listOfPeople,
  clear,
  onUpdate
}: SearchResultProps) => {
  const router = useRouter();
  const { setActiveNodeId } = useNode();

  const { users } = useMe();
  const trackEvent = useAmplitude();

  const nodeClickHandler = (nodeId: number) => {
    clear();
    setActiveNodeId(nodeId)
    trackEvent(EventTypes.SEARCH_RESULT_CLICKED)
  };
  const listOfPeopleJsx = listOfPeople.map((user, i) => {
    return <StyledPerson
        key={i}
        person={user}
        onClick={() => router.push({name: 'commonRelations', params: {fromUserId: user.id}})}
    />
});
  const listOfNodesJsx = listOfNodes.map((node) => {
    return <StyledNode
      key={node.id}
      node={node}
      onUpdate={onUpdate}
      users={users}
      importantUserIds={[]}
      onClick={() => nodeClickHandler(node.id)}
    />;
  });
  return (
    <StyledWrapper>
      {!!listOfPeople.length && <GroupLayout title="People">
          {listOfPeopleJsx}
      </GroupLayout>}
      {!!listOfNodes.length &&<GroupLayout title="Resources">
          {listOfNodesJsx}
      </GroupLayout>}
    </StyledWrapper>
  );
};
