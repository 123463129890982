import { FC, useEffect } from "react";
import { StyledTab, StyledTabs, StyledWrapper } from "./Tabs.styled";
import { TabValueType } from "@/providers/v3/ForYouPage/ForYouPage.type";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";

export const Tabs: FC = () => {
  const { activeTab, setActiveTab } = useForYouPage();
  const shouldRenderTabsFromSidebar = useRenederBelow('medium');
  useEffect(() => {
    if (!shouldRenderTabsFromSidebar && activeTab === 'MEETINGS') {
      setActiveTab('UPDATES');
    }
}, [activeTab, setActiveTab, shouldRenderTabsFromSidebar]);
  return <StyledWrapper>
    <StyledTabs value={activeTab} onChange={(newVal) => setActiveTab(newVal as TabValueType)}>
      <StyledTab value="UPDATES">Updates</StyledTab>
      {shouldRenderTabsFromSidebar && <StyledTab value="MEETINGS">Meetings</StyledTab>}
      <StyledTab value="EMAILS">Emails</StyledTab>
      <StyledTab value="NOTES">Notes</StyledTab>
      <StyledTab value="ACTIVITIES">Activities</StyledTab>
      
    </StyledTabs>
  </StyledWrapper>
};