import { FC, useEffect, useState } from "react";
import { StyledDeclineAllButton, StyledFilledCard, StyledSectionHeader, StyledSectionHeaderActions, StyledShowAllButton, StyledWrapper } from "./SuggestedToBeAdded.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { ScaledCloseIcon } from "@/icons/v3/close.icon";
import { DoneIcon } from "@/icons/v3/done.icon";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { DeleteSpaceSuggestionsFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { Node } from "./components/Node/Node.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { ToastService } from "@/service/ToastService";
import { AddNodeToSpaceFetch } from "@/axios/AiService/SpacesNodes/SpacesNodes.api";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { useShowMore } from "@/hooks/v3/UseShowMore/UseShowMore.hook";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";

export const SuggestedToBeAdded: FC = () => {
    const { space } = useSpacePageCommon();
    const { listOfSuggestions, update, isFetchingListOfSpaceSuggestions, listOfLastRecentActivities, isFetchingListOfLastRecentActivities } = useSpacePage();
    const { items, showMore, setShowMore } = useShowMore({ items: listOfSuggestions, limit: 5 });
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const { users } = useMe();
    const trackEvent = useAmplitude();
    
    useEffect(() => {
        setIsFirstRender(false);
    }, [isFetchingListOfLastRecentActivities]);
    const addToKeyResources = async (nodeId: number) => {
        if (!space) return;
        setIsFetching(true);
        await AddNodeToSpaceFetch({
            spaceId: space.id,
            nodeIds: [nodeId],
        }).then(async () => {
            ToastService.showToast('success', '1 resource was added to the space!');
            trackEvent(EventTypes.SUGGESTION_ACCEPTED);
            update();
        })
        .catch(() => {
            ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
        });
        setIsFetching(false);
    };
    const addAllHandler = async () => {
        if (!space) return;
        setIsFetching(true);
        await AddNodeToSpaceFetch({
            spaceId: space.id,
            nodeIds: listOfSuggestions.map((suggestion) => suggestion.id),
        }).then(async () => {
            ToastService.showToast('success', `${listOfSuggestions.length} resource were added to the space!`);
            update();
            trackEvent(EventTypes.ALL_SUGGESTIONS_ACCEPTED);
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
        });
        setIsFetching(false);
    };
    const deleteNodeFromSuggestionsHandler = async (nodeId: number) => {
        if (!space) return;
        setIsFetching(true);
        await DeleteSpaceSuggestionsFetch(space.id, {
            nodeIds: [nodeId]
        }).then(() => {
            update();
            ToastService.showToast('success', '1 suggestion was declined!');
        });
        trackEvent(EventTypes.SUGGESTION_DECLINED);
        setIsFetching(false);
    };
    const declineAllHandler = async () => {
        if (!space) return;
        setIsFetching(true);
        await DeleteSpaceSuggestionsFetch(space.id, {
            nodeIds: listOfSuggestions.map((suggestion) => suggestion.id)
        }).then(() => {
            update();
            ToastService.showToast('success', `${listOfSuggestions.length} suggestions were declined`);
            trackEvent(EventTypes.ALL_SUGGESTIONS_DECLINED);
        });
        setIsFetching(false);
    };
    const listOfSuggestedNodes = items.map((suggestion, i) => {
        return <Node
            onAdd={addToKeyResources}
            onDecline={deleteNodeFromSuggestionsHandler}
            node={suggestion}
            users={users}
            onUpdate={update}
            importantUserIds={[]}
            key={i}
        />
    });
    const isEmpty = !listOfSuggestions.length && !isFetchingListOfSpaceSuggestions;
    console.log('1', !!listOfLastRecentActivities.length);
    console.log('2', (!isFetchingListOfLastRecentActivities || !isFirstRender));
    console.log('2 1', !isFetchingListOfLastRecentActivities);
    console.log('2 2', !isFirstRender);
    
    const isNotEmptySpace = !!listOfLastRecentActivities.length && (!isFetchingListOfLastRecentActivities || !isFirstRender)
    return <>
        {isNotEmptySpace && <EmptyStateLayoyt value={isEmpty} emptyNode={<></>} >
            <LoaderLayout isLoading={isFetching} transparent>
                <StyledWrapper>
                    <StyledSectionHeader title="Suggested to be added">
                        <StyledSectionHeaderActions>
                            <Button onClick={addAllHandler} variant="secondary" icon={<DoneIcon />}>Add all</Button>
                            <StyledDeclineAllButton onClick={declineAllHandler} variant="secondary" icon={<ScaledCloseIcon />} >Decline all</StyledDeclineAllButton>
                        </StyledSectionHeaderActions>
                        
                    </StyledSectionHeader>
                    <LoaderLayout isLoading={isFetchingListOfSpaceSuggestions} transparent={!!listOfSuggestions.length}>
                        <StyledFilledCard>
                            {listOfSuggestedNodes}
                            {!showMore && listOfSuggestions.length > items.length && <StyledShowAllButton variant='tertiary' onClick={() => setShowMore(true)}>
                                Show all
                            </StyledShowAllButton>}
                        </StyledFilledCard>
                    </LoaderLayout>
                </StyledWrapper>
            </LoaderLayout>
        </EmptyStateLayoyt>}
    </>
};