import { useMemo } from "react";
import {
    FormControlLabel,
    Checkbox as CheckBoxUI
} from "@mui/material";
import { CheckboxProps } from "./Checkbox.type";
import { Checkbox as CheckboxField } from "../../../v3/Fields/Checkbox/Checkbox.component";

export const Checkbox = <T,>({
    value,
    onChange,
    val,
    label
}: CheckboxProps<T>) => {
    const checked = useMemo(() => value.includes(val), [val, value]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        if (checked) {
            onChange([...value, val]);
        } else {
            onChange(value.filter((v) => v !== val));
        }
    };
    return <FormControlLabel control={<CheckboxField checked={checked} onChange={handleChange} />} label={label} />;
};
