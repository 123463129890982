import { styled } from "@mui/material";
import { UserAvatar } from "../../Other/UserAvatar/UserAvatar.component";
import { TextSkeleton } from "../../Skeleton/TextSkeleton/TextSkeleton.component";

export const StyledWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const StyledContent = styled('div', {
    shouldForwardProp: (prop) => prop !== 'haveAvatar'
})<{
    haveAvatar: boolean;
}>`
    width: ${({ haveAvatar }) => haveAvatar ? 'calc(100% - 40px)' : '100%'};
`;
export const StyledTitle = styled(TextSkeleton)`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;
export const StyledCaption = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const StyledImgWrapper = styled('div')`
    margin-right: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledUserAvatar = styled(UserAvatar)`
    margin-right: 8px;
`;