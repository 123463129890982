import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { UpdateSpaceBodyParams } from "./Types/Update/Update.type";
import { CreateKeyResourcesGetQueryParams } from "./Types/CreateKeyResources/CreateKeyResources.type";
import { DeleteKeyResourcesGetQueryParams } from "./Types/DeleteKeyResources/DeleteKeyResources.type";
import { DeleteKeyResourcesSuggestionsGetQueryParams } from "./Types/DeleteKeyResourcesSuggestions/DeleteKeyResourcesSuggestions.type";
import { CreateSpaceBodyParams } from "./Types/CreateSpace/CreateSpace.type";
import { DeleteAiSuggestionsBodyParams } from "./Types/DeleteAiSuggestions/DeleteAiSuggestions.type";
import { CreateRelationBodyParams, CreateRelationQueryParams } from "./Types/CreateRelation/CreateRelation.type";
import { GetSpacesRelationsQueryParams } from "./Types/GetRelations/GetRelations.type";

export const GetSpacesFetch = () => {
    return HttpClient.get(`${envUrl}/ai-service/v1/spaces`);
}
export const GetSpaceRelationsFetch = (query: GetSpacesRelationsQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/spaces/relations`, {
        params: {
            ...query,
            relations: query.relations.join(',')
        }
    });
}
export const GetSpaceFetch = (spaceId: string) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/spaces/${spaceId}`);
}
export const UpdateSpaceFetch = (spaceId: string, body: UpdateSpaceBodyParams) => {
    return HttpClient.put(`${envUrl}/ai-service/v1/spaces/${spaceId}`, body);
}
export const DeleteSpaceFetch = (spaceId: string) => {
    return HttpClient.delete(`${envUrl}/ai-service/v1/spaces/${spaceId}`);
}
export const GetSpaceKeyResourcesFetch = (spaceId: string) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/spaces/${spaceId}/key-resources`);
}
export const CreateSpaceKeyResourcesFetch = (spaceId: string, query: CreateKeyResourcesGetQueryParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/spaces/${spaceId}/key-resources`, null, {
        params: query
    });
}
export const DeleteSpaceKeyResourcesFetch = (spaceId: string, query: DeleteKeyResourcesGetQueryParams) => {
    return HttpClient.delete(`${envUrl}/ai-service/v1/spaces/${spaceId}/key-resources`, {
        params: query
    });
}
export const GetSpaceKeyResourcesSuggestionsFetch = (spaceId: string) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/spaces/${spaceId}/key-resources/suggestions`);
}
export const DeleteSpaceKeyResourcesSuggestionsFetch = (spaceId: string, query: DeleteKeyResourcesSuggestionsGetQueryParams) => {
    return HttpClient.delete(`${envUrl}/ai-service/v1/spaces/${spaceId}/key-resources/suggestions`, {
        params: query
    });
}
export const GetSpaceSuggestionsFetch = (spaceId: string) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/spaces/${spaceId}/ai-suggestions`);
}
export const DeleteSpaceSuggestionsFetch = (spaceId: string, body: DeleteAiSuggestionsBodyParams) => {
    return HttpClient.delete(`${envUrl}/ai-service/v1/spaces/${spaceId}/ai-suggestions`, {
        data: body
    });
}
export const CreateSpaceFetch = (body: CreateSpaceBodyParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/spaces`, body);
}
export const CreateSpaceRelationFetch = (body: CreateRelationBodyParams, query: CreateRelationQueryParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/spaces/relations`, body, {
        params: query
    });
}