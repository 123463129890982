import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { AllRelationshipsProps, NodeWithActitvitiesType } from "./AllRelationships.type";
import { NodeTypeType } from "@/types/Node/Node.type";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { getAppOptionsFromNodeWrappers } from "@/utils/v3/GetAppOptionsFromNodes/GetAppOptionsFromNodes.util";
import { getPeopleOptionsFromNodes } from "@/utils/v3/GetPeopleOptionsFromNodes/GetPeopleOptionsFromNodes.util";
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { StyledFiltersWrapper, StyledSelect, StyledWrapper } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { ToastService } from "@/service/ToastService";
import { fetchNodeRelations } from "@/utils/FetchNodeRelations/FetchNodeRelations.util";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { WalkthroughWrapper } from "@/providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";
import { GetNodeRecentActionsFetch } from "@/axios/AiService/NodeRecentActions/NodeRecentActions.api";
import { NodeRecentActionsAxiosResponseSuccessType } from "@/axios/AiService/NodeRecentActions/Types/Get/Get.type";
import { CategoryGroup } from "@/components/v3/List/CategoryGroup/CategoryGroup.component";
import { groupNodeWrappersByCategory } from "@/utils/v3/GroupNodeByCategory/GroupNodeByCategory.util";

export const AllRelationships: FC<AllRelationshipsProps> = ({
    isFetchingCommonNodes,
    commonNodes,
    onUpdate
}) => {
    const [recentActivities, setRecentActivities] = useState<NodeWithActitvitiesType[]>([]);
    const [commonNodesWithRelationships, setCommonNodesWithRelationships] = useState<NodeWithActitvitiesType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedApps, setSelectedApps] = useState<string[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const allRelationships: NodeWithActitvitiesType[] = useMemo(() => {
        const wrappers = [...commonNodesWithRelationships, ...recentActivities];
        const filteredNodes = wrappers.filter(wrapper => wrapper.node.nodeType !== NodeTypeType.EVENT && wrapper.node.nodeType !== NodeTypeType.CALENDAR);
        return filteredNodes;
    }, [commonNodesWithRelationships, recentActivities]);
    const { users } = useMe();
    const router = useRouter();
    const fromUserId = router.params.fromUserId as string;
    const appsOptions = useMemo((): OptionType<string>[] => {
        return getAppOptionsFromNodeWrappers(allRelationships);
    }, [allRelationships]);
    const peopleOptions = useMemo((): OptionType<string>[] => {
        return getPeopleOptionsFromNodes(allRelationships)
    }, [allRelationships]);
    const loadRecentActivitiesNodes = useCallback(async () => {
        await GetNodeRecentActionsFetch({
            userId: fromUserId,
            limit: 30,
        }).then(async (resRecentActivities: NodeRecentActionsAxiosResponseSuccessType) => {
            const listOfRelationships = await fetchNodeRelations(users, resRecentActivities.data.map((node) => node.id));
            const recentActivitiesNodeIds = resRecentActivities.data.map((node) => node.id);
            const importantUsers = await fetchImportantUsers(recentActivitiesNodeIds);
            const nodesWithActivities = resRecentActivities.data.map((node) => {
                const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
                const nodeWithActivities: NodeWithActitvitiesType = {
                    node: node,
                    messages: [],
                    relationships: listOfRelationships.find((el) => el.nodeId === node.id),
                    importantUserIds: importantInfo.userIds
                };
                return nodeWithActivities;
            });
            setRecentActivities(nodesWithActivities);
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
        });
        setIsLoading(false)
    }, [fromUserId, users]);
    useEffect(() => {
        loadRecentActivitiesNodes();
    }, [loadRecentActivitiesNodes])
    const loadRelationshipsForCommonNodes = useCallback(async () => {
        const listOfRelationships = await fetchNodeRelations(users, commonNodes.map((wrapper) => wrapper.node.id));
        const recentActivitiesNodeIds = commonNodes.map((wrapper) => wrapper.node.id);
        const importantUsers = await fetchImportantUsers(recentActivitiesNodeIds);
        const nodesWithActivities = commonNodes.map((wrapper) => {
            const importantInfo = importantUsers.find(info => info.nodeId === wrapper.node.id)!
            const nodeWithActivities: NodeWithActitvitiesType = {
                ...wrapper,
                messages: [],
                relationships: listOfRelationships.find((el) => el.nodeId === wrapper.node.id),
                importantUserIds: importantInfo.userIds
            };
            return nodeWithActivities;
        });
        setCommonNodesWithRelationships(nodesWithActivities);
    }, [commonNodes, users]);
    useEffect(() => {
        loadRelationshipsForCommonNodes();
    }, [loadRelationshipsForCommonNodes])
    const update = useCallback(async () => {
        loadRecentActivitiesNodes();
        loadRelationshipsForCommonNodes();
        onUpdate();
    }, [loadRecentActivitiesNodes, loadRelationshipsForCommonNodes, onUpdate]);
    const allNodes = useMemo(() => [...commonNodes, ...recentActivities], [commonNodes, recentActivities]);
    const filteredListOfNodes = useMemo(() => {
        return allNodes.filter((wrapper) => {
            const selectedAppsLabels = appsOptions.filter((option) => selectedApps.includes(option.value)).map((option) => option.label);
            if (selectedApps.length && !selectedAppsLabels.includes(wrapper.node.application)) {
                return false;
            }
            if (selectedPeople.length) {
                const actorIds = (wrapper as NodeWithActitvitiesType).relationships?.actors?.map(actor => actor.userId) || [];
                if (!actorIds.some(id => selectedPeople.includes(id))) {
                    return false;
                }
            }
            return true;
        });
    }, [allNodes, appsOptions, selectedApps, selectedPeople]);
    const allGroups = useMemo(() => {
        return groupNodeWrappersByCategory(allNodes);
    }, [allNodes]);
    const groups = useMemo(() => {
        return groupNodeWrappersByCategory(filteredListOfNodes);
    }, [filteredListOfNodes]);
    const filteredGroups = useMemo(() => {
        if (!selectedCategories.length) {
            return groups;
        }
        return groups.filter((group) => {
            return selectedCategories.includes(group.category);
        });
    }, [groups, selectedCategories]);
    const listOfCategoriesJsx = filteredGroups.map((group, i) => {
        return <CategoryGroup useShowAll title={group.category} nodes={group.wrappers.map((wrapper) => {
            return {
                node: wrapper.node,
                users: users,
                onUpdate: update,
                importantUserIds: wrapper.importantUserIds
            }
        })} key={i} />
    });
    return <LoaderLayout isLoading={isFetchingCommonNodes || isLoading}>
        <StyledWrapper>
            <StyledFiltersWrapper>
                <StyledSelect
                    label="Categories"
                    value={selectedCategories}
                    onChange={(val: any) => setSelectedCategories(val)}
                    options={allGroups.map((group) => {
                        return { label: group.category, value: group.category}
                    })}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    icon={<FilterIcon />}
                />
                <StyledSelect
                    label="Apps"
                    value={selectedApps}
                    onChange={(val: any) => setSelectedApps(val)}
                    options={appsOptions}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    icon={<FilterIcon />}
                />
                <WalkthroughWrapper topContainerMargin={56} variant="walkthrough-updates" title="All updates at one place" description={<>
                    <div>Project-related recent discussions, decisions, updated resources, tasks, etc. </div>
                    <div>Use filters to quickly get what you are looking for.</div>
                </>} targetId="walkthrough-updates" >
                    <StyledSelect
                        label="People"
                        value={selectedPeople}
                        onChange={(val: any) => setSelectedPeople(val)}
                        options={peopleOptions}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                        id="walkthrough-updates"
                    />
                </WalkthroughWrapper>
            </StyledFiltersWrapper>
            {listOfCategoriesJsx}
        </StyledWrapper>
    </LoaderLayout>
};