import { useMemo } from "react";
import { PesonWrapper, PesonName, PesonAction } from "./Person.styled";
import { PersonProps } from "./Person.type";
import { actorActionToString } from "utils/ActorActionToString/ActorActionToString.util";
import { getFullName } from "@/utils/GetFullName/GetFullName.util";
import { UserAvatar } from "../../../../../../../../../../Other/UserAvatar/UserAvatar.component";

export const Person = ({ user, actionType }: PersonProps) => {
    const action = useMemo(() => actorActionToString(actionType), [actionType]);
    const fullName = useMemo(() => {
        return getFullName(user.firstName, user.lastName);
    }, [user.firstName, user.lastName]);
    return (
        <PesonWrapper>
            <UserAvatar avatar={user.avatar} />
            <div>
                <PesonName>{fullName}</PesonName>
                <PesonAction>{action}</PesonAction>
            </div>
        </PesonWrapper>
    )
}