import { useMemo } from "react";
import { PesonContainer } from "./Person.styled";
import { PersonProps } from "./Person.type";
import { actorActionToString } from "utils/ActorActionToString/ActorActionToString.util";
import { getFullName } from "@/utils/GetFullName/GetFullName.util";
import { UserAvatar } from "../../../../../../../../Other/UserAvatar/UserAvatar.component";

export const Person = ({ user, actionType, useSkeleton, isExpired = false }: PersonProps) => {
    const action = useMemo(() => actorActionToString(actionType), [actionType]);
    const fullName = useMemo(() => {
        return getFullName(user.firstName, user.lastName);
    }, [user.firstName, user.lastName]);

    const title = `${fullName} · ${action}`;

    return <PesonContainer>
        <UserAvatar useSkeleton={useSkeleton} avatar={user.avatar} title={title} isExpired={isExpired} />
    </PesonContainer>
}