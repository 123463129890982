import { CalendarIcon } from "@/icons/v3/calendar.icon";
import { styled } from "@mui/material";
import { TextSkeleton } from "../../Skeleton/TextSkeleton/TextSkeleton.component";

export const StyledCalendarIcon = styled(CalendarIcon)`
    width: 16px;
    height: 16px;
`;
export const StyledTitleText = styled(TextSkeleton)`
    margin-left: 4px;
`;