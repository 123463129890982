import { FC, useMemo, useState } from "react";
import { StyledFiltersWrapper, StyledSelect, StyledWrapper } from "./AllResources.styled";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { groupNodeWrappersByCategory } from "@/utils/v3/GroupNodeByCategory/GroupNodeByCategory.util";
import { CategoryGroup } from "@/components/v3/List/CategoryGroup/CategoryGroup.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";

export const AllResources: FC = () => {
    const {
        listOfSpaceApplications,
        listOfSpaceUsers,
    } = useSpacePageCommon();
    const {
        selectedApps, setSelectedApps,
        selectedPeople, setSelectedPeople,
        listOfAllResources,
        isFetchingAllResources,
        update,
    } = useSpacePage();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const allGroups = useMemo(() => {
        return groupNodeWrappersByCategory(listOfAllResources);
    }, [listOfAllResources]);
    const filteredListOfNodes = useMemo(() => {
        return listOfAllResources.filter((wrapper) => {
            const selectedAppsLabels = listOfSpaceApplications.filter((application) => selectedApps.includes(application.id)).map((app) => app.name);
            if (selectedApps.length && !selectedAppsLabels.includes(wrapper.node.application)) {
                return false;
            }
            if (selectedPeople.length) {
                const actorIds = wrapper.relationships?.actors?.map(actor => actor.userId) || [];
                if (!actorIds.some(id => selectedPeople.includes(id))) {
                    return false;
                }
            }
            return true;
        });
    }, [listOfAllResources, listOfSpaceApplications, selectedApps, selectedPeople]);
    const groups = useMemo(() => {
        return groupNodeWrappersByCategory(filteredListOfNodes);
    }, [filteredListOfNodes]);
    const filteredGroups = useMemo(() => {
        if (!selectedCategories.length) {
            return groups;
        }
        return groups.filter((group) => {
            return selectedCategories.includes(group.category);
        });
    }, [groups, selectedCategories]);
    const listOfCategoriesJsx = filteredGroups.map((group, i) => {
        return <CategoryGroup useShowAll title={group.category} nodes={group.wrappers.map((wrapper) => {
            return {
                node: wrapper.node,
                users: listOfSpaceUsers,
                relationships: wrapper.relationships,
                onUpdate: update,
                importantUserIds: wrapper.importantUserIds
            }
        })} key={i} />
    });
    return <LoaderLayout isLoading={isFetchingAllResources} transparent={!!listOfAllResources.length}>
        <StyledWrapper>
            <StyledFiltersWrapper>
                <StyledSelect
                    label="Categories"
                    value={selectedCategories}
                    onChange={(val: any) => setSelectedCategories(val)}
                    options={allGroups.map((group) => {
                        return { label: group.category, value: group.category}
                    })}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    icon={<FilterIcon />}
                />
                <StyledSelect
                    label="Apps"
                    value={selectedApps}
                    onChange={(val: any) => setSelectedApps(val)}
                    options={listOfSpaceApplications.map((app) => {
                        return { label: `${app.name}`, value: app.id}
                    })}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    icon={<FilterIcon />}
                />
                <StyledSelect
                    label="People"
                    value={selectedPeople}
                    onChange={(val: any) => setSelectedPeople(val)}
                    options={listOfSpaceUsers.map((user) => {
                        return { label: `${user.firstName} ${user.lastName}`, value: user.id, img: user.avatar || "/icons/no_avatar.svg"}
                    })}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    icon={<FilterIcon />}
                />
            </StyledFiltersWrapper>
            {listOfCategoriesJsx}
        </StyledWrapper>
    </LoaderLayout>
};