import { FC } from "react";
import { StyledAtionsWrapper, StyledContent, StyledDescription, StyledDescriptionWrapper, StyledImage, StyledSkipButton, StyledTitle, StyledWrapper } from "./FirstExperienceLayout.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { FirstExperienceLayoutProps } from "./FirstExperienceLayout.type";
import { ProgressLine } from "./components/ProgressLine/ProgressLine.component";

export const FirstExperienceLayout: FC<FirstExperienceLayoutProps> = ({
    title,
    description,
    onClick,
    onSkip,
    progress,
    buttonText
}) => {
    return <StyledWrapper>
        <StyledContent>
            <StyledImage />
            <StyledDescriptionWrapper>
                <StyledTitle>{title}</StyledTitle>
                <StyledDescription>{description}</StyledDescription>
                <ProgressLine progress={progress} />
            </StyledDescriptionWrapper>
        </StyledContent>
        <StyledAtionsWrapper>
            {!!onSkip && <StyledSkipButton size="large" variant="tertiary" onClick={onSkip}>Skip</StyledSkipButton>}
            <Button size="large" onClick={onClick}>{buttonText}</Button>
        </StyledAtionsWrapper>
    </StyledWrapper>
};