import { Dialog, styled } from "@mui/material";
import { SizeType } from "./Dialog.type";
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";

export const StyledDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== 'size'
})<{size: SizeType}>`
    & .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.5);
    }
    ${props => props.size === 'small' && `
        & .MuiPaper-root {
            max-width: 560px;
            width: 560px;
            @media (max-width: 600px) {
                width: 80%;
                minWidth: 0;
            }
        }
    `}
    ${props => props.size === 'large' && `
        & .MuiPaper-root {
            max-width: 720px;
            width: 720px;
            @media (max-width: 800px) {
                width: 80%;
                minWidth: 0;
            }
        }
    `}
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        & .MuiPaper-root {
            max-width: 100%;
            width: 100%;
            margin: 0;
            max-height: 100%;
            height: 100%;
        }
    }
`;