import { ActivityMessageOrComment } from "@/components/v3/List/ActivityMessageOrComment/ActivityMessageOrComment.component";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { CalendarIcon } from "@/icons/v3/calendar.icon";
import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    padding: 24px;
`;
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 8px;
`;
export const StyledTitleText = styled('div')`
    margin-left: 4px;
`;
export const StyledCalendarIcon = styled(CalendarIcon)`
    width: 16px;
    height: 16px;
`;
export const StyledComment = styled(ActivityMessageOrComment)`
    padding-left: 0;
    padding-right: 0;
    margin-top: 0px;
    margin-bottom: 0;
    &:last-child {
        margin-bottom: 0;
    }
`;
export const StyledActivityMessageWrapper = styled('div')`
    margin-bottom: 8px;
`;
export const StyledActivityMessage = styled(ActivityMessageOrComment)`
    padding-left: 0;
    padding-right: 0;
    margin-top: 0px;
    background: #FFF;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
`;