import { Box } from "@mui/material";
import { ManageButton } from "./components/ManageButton/ManageButton.component";
import { useMe } from "providers/MeProvider/Me.provider";
import { IntegrationProps } from "./mIntegration.type";
import {
    StyledActionsWrapper,
    StyledListItemNode,
    StyledListItem,
    StyledMobileActionsWrapper,
} from "./Integration.styled";
import { openConectAppLink } from "@/utils/v3/OpenConectAppLink/OpenConectAppLink.util";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useMemo } from "react";
import { useRouter } from "@/providers/Router/Router.provider";
import { defineApp } from "../../ManageIntegrationsContent.utils";

export const Integration = ({
        servicesIntegrationStatus,
        icon,
        appName,
        accounts,
        disconnectHandler,
        type,
        caption: captionProp
    }: IntegrationProps) => {
    const { user } = useMe();
    const isMobile = useRenederBelow('mobileRotate');
    const router = useRouter();
    const isOnboarding = router.name === 'manageIntegrations';
    const caption = useMemo(() => {
        if (accounts?.length) {
            return `${accounts.length} account${accounts.length > 1 ? 's' : ''} connected`;
        }
        return captionProp;
    }, [accounts, captionProp]);
    return (
        <StyledListItemNode bottomButtons={isMobile && !!accounts?.length} >
            <StyledListItem
                rounded={false}
                img={icon}
                title={appName}
                caption={caption}
                imgSize="large"
            />
            <Box>
                {!servicesIntegrationStatus && (
                    <Button onClick={() => {openConectAppLink(type, user?.id, {isOnboarding})}} >Connect</Button>
                )}
                {servicesIntegrationStatus && (
                    <Box>
                        <StyledActionsWrapper>
                            {!!accounts?.length && !isMobile && <>
                                <Button variant="secondary" onClick={() => {
                                    openConectAppLink(type, user?.id, {isOnboarding})
                                }}>
                                    Add Account
                                </Button>
                                <ManageButton icon={icon} connectHandler={openConectAppLink} disconnectHandler={disconnectHandler} accounts={accounts} appName={appName}/>
                            </>}
                            {!accounts?.length && <Button variant="tertiary" onClick={() => {
                                disconnectHandler(defineApp(appName))
                            }}>
                                Disconnect
                            </Button>}
                        </StyledActionsWrapper>
                    </Box>
                )}
                {isMobile && !!accounts?.length && <StyledMobileActionsWrapper>
                    <Button variant="secondary" center onClick={() => {
                        openConectAppLink(type, user?.id, {isOnboarding})
                    }}>
                        Add Account
                    </Button>
                    <ManageButton bottomButtons icon={icon} connectHandler={openConectAppLink} disconnectHandler={disconnectHandler} accounts={accounts} appName={appName}/>
                </StyledMobileActionsWrapper>}
            </Box>
        </StyledListItemNode>
    )
}