import { SettingsModalProps } from "./SettingsModal.type";
import { Content } from "./components/Content/Content.component";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";

export const SettingsModal = ({ value, onChange }: SettingsModalProps) => {
  return <Dialog
    value={value}
    onChange={onChange}
    size="small"
  >
    <DialogLayout title="Settings" onCloseClick={() => onChange(false)} >
      <Content />
    </DialogLayout>
  </Dialog>
}