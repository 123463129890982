import { FC } from "react";
import { ProgressLineProps } from "./ProgressLine.type";
import { StyledLine, StyledWrapper } from "./ProgressLine.styled";

export const ProgressLine: FC<ProgressLineProps> = ({
    progress
}) => {
    const width = progress * 100;
    return <StyledWrapper>
        <StyledLine width={width} />
    </StyledWrapper>
};