import { FC } from "react";
import { DialogLayoutProps } from "./DialogLayout.type";
import { StyledActions, StyledContent, StyledHeader, StyledLoaderLayout, StyledWrapper } from "./DialogLayout.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { ScaledCloseIcon } from "@/icons/v3/close.icon";

export const DialogLayout: FC<DialogLayoutProps> = ({
    children,
    title,
    actions,
    onCloseClick,
    className,
    isLoading,
    useContetPaddings = true
}) => {
    return (
        <StyledLoaderLayout isLoading={isLoading} transparent>
            <StyledWrapper className={`${className} scroll-dialog-wrapper`}>
                <StyledHeader>
                    {title}
                    {!!onCloseClick && <Button onClick={onCloseClick} icon={<ScaledCloseIcon />} variant="tertiary" />}
                </StyledHeader>
                {children && <StyledContent useContetPaddings={useContetPaddings}>{children}</StyledContent>}
                {!!actions && <StyledActions>{actions}</StyledActions>}
            </StyledWrapper>
        </StyledLoaderLayout>
    );
};