import { useHistory } from 'react-router-dom';
import { HttpClient } from '../../../axios/HttpClient';
import { ToastService } from '../../../service/ToastService';
import { envUrl } from "../../../service/helpers";
import { useMe } from 'providers/MeProvider/Me.provider';
import { AuthResponse, ErrorsType } from './SignupFromInvite.type';
import { StyledFormWrapper } from './SignupFromInvite.styled';
import { useRouter } from '@/providers/Router/Router.provider';
import { GetMeFetch } from '@/axios/SenseFacade/Users/Users.api';
import { UserMeAxiosResponseSuccessType } from '@/axios/SenseFacade/Users/Types/GetMe/GetMe.type';
import { StyledLoginPage, StyledTitle, StyledCard, StyledInput, StyledSubmitButton } from '../Login/Login.styled';
import { Terms } from '../Login/components/Terms/Terms.component';
import { useState } from 'react';

export const SignupFromInvite = () => {
  const history = useHistory();
  const router = useRouter();
  const { login, loadUser } = useMe();

  const email = router.query.email as string;
  const confirmationCode = router.query.code as string;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorsType>({});
  const [fullName, setFullName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');
  const validate = () => {
    let tempErrors: ErrorsType = {};

    if (!fullName) tempErrors.fullName = "Full name is required";
    if (!password) tempErrors.password = "Password is required";
    else if (password.length < 8) tempErrors.password = "Password is too short - should be 8 chars minimum";
    else if (!/\d/.test(password)) tempErrors.password = "Password must contain at least one number character";
    else if (!/[!@#$%^&*]/.test(password)) tempErrors.password = "Password must contain at least one special character";

    if (!rePassword) tempErrors.rePassword = "Please confirm your password";
    else if (rePassword !== password) tempErrors.rePassword = "Passwords must match";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const submit = async () => {
    if (validate()) {
      const createUserRequest = {
        email: email,
        fullName: fullName,
        password: password,
        code: confirmationCode
      };
      setIsLoading(true);
      await HttpClient.post(`${envUrl}/organization-service/v1/users`, createUserRequest)
        .then(response => response.data)
        .then(data => {
          ToastService.showToast('success', 'User successfully created');
        })
        .then(() => {
          HttpClient.post(`${envUrl}/organization-service/v1/users/login`, {
            email: email,
            password: password,
          })
            .then(response => response.data)
            .then((authResponse: AuthResponse) => {
              login(authResponse.accessToken, async () => {
                await GetMeFetch().then(async (res: UserMeAxiosResponseSuccessType) => {
                  const { accountReady, emailConfirmed, isOnboarded } = res.data.user;
                  if (!isOnboarded) {
                    await HttpClient.put(`${envUrl}/organization-service/v1/payment/subscription`, {
                      name: 'INDIVIDUAL',
                      userId: res.data.user.id,
                    });
                    localStorage.setItem("onboardingStep", "manageIntegrations");
                    router.push({
                        name: 'manageIntegrations'
                    })
                    // await HttpClient.patch(`${envUrl}/organization-service/v1/users/${res.data.user.id}`, {
                    //   onboarded: true,
                    // })
                  }
                  if (!res.data.user.firstLogin) {
                    HttpClient.patch(`${envUrl}/organization-service/v1/users/${res.data.user.id}`, {
                      firstLogin: true,
                    });
                  }
                  await loadUser()
                  
                  const pathFromLink = localStorage.getItem('pathFromLink');
    
                  if (!emailConfirmed) {
                    router.push({
                      name: 'linkConfirmation',
                      query: router.query
                    })
                    return;
                  }
                  else if (!accountReady) {
                    router.push({
                      name: 'loadingPage',
                      query: router.query
                    })
                    return;
                  }
                  else if (!isOnboarded) {
                    // start disable onboarding
                    // if (res.data.user.paymentPlan.name === 'PRO') {
                    //   router.push({
                    //     name: 'manageIntegrations'
                    //   })
                    //   localStorage.setItem("onboardingStep", "manage-integrations");
                    // } else {
                    //   router.push({
                    //     name: 'selectPlan'
                    //   })
                    //   localStorage.setItem("onboardingStep", "select-plan");
                    // }
                    if (router.query.redirectUrl) {
                      window.location.href = `${window.location.origin}${router.query.redirectUrl}`;
                    } else {
                      router.push({
                        name: 'forYou',
                        query: router.query
                      })
                    }
                    return;
                    // end disable onboarding
                  } else {
                    const path = pathFromLink && pathFromLink !== '/login' ? pathFromLink : '/for-you';
                    localStorage.removeItem('pathFromLink');
                    history.push(path);
                  }
                  const selectedSpace = localStorage.getItem('selectedSpace');
                  if (!selectedSpace) {
                    localStorage.setItem('selectedSpace', 'All Spaces')
                  }
                })
    
                // router.push({
                //   name: 'forYou'
                // });
              });
            })
            .catch(error => {
              ToastService.showToast('error', error?.response?.data?.errorMessage);
            });
        })
        .catch(error => {
          ToastService.showToast('error', error?.response?.data?.errorMessage);
        });
      setIsLoading(false);
    };
  };
  return (
    <StyledLoginPage transparent isLoading={isLoading}>
      <StyledFormWrapper>
        <StyledTitle>Create Sense account</StyledTitle>
        <StyledCard>
          <div>
            <StyledInput error={errors.fullName} value={fullName} onChange={(e) => setFullName(e.target.value)} label="Full name" />
          </div>
          <div>
            <StyledInput error={errors.password} value={password} onChange={(e) => setPassword(e.target.value)} label="Password" type="password" />
          </div>
          <div>
            <StyledInput error={errors.rePassword} value={rePassword} onChange={(e) => setRePassword(e.target.value)} label="Confirm password" type="password" />
          </div>
          <StyledSubmitButton size="large" onClick={submit}>Create account</StyledSubmitButton>
        </StyledCard>
      </StyledFormWrapper>
      <Terms />
    </StyledLoginPage>
  );
};
