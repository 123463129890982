import { InputProps } from './Input.type';
import { StyledTextField } from './Input.styled';
import { FC } from 'react';

export const Input: FC<InputProps> = ({ type, label, value, onChange, error, variant = 'standard', size = 'large', ...rest }) => {
  return (
    <StyledTextField
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      variant="outlined"
      inputVariant={variant}
      {...rest}
      error={!!error}
      helperText={error ? error : rest.helperText}
      inputSize={size}
      inputIsFilled={!!value}
    />
  );
};
