import { styled } from "@mui/material";

export const StyledPeopleContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
`
export const StyledButton = styled('div')`
    width: 28px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -8px;
    background: #ffffff;
    border-radius: 50%;
`
export const StyledButtonContent = styled('div')`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #636363
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`