import { FC } from "react";
import { ListItemProps } from "./ListItem.type";
import { StyledCaption, StyledContent, StyledImgWrapper, StyledTitle, StyledUserAvatar, StyledWrapper } from "./ListItem.styled";

export const ListItem: FC<ListItemProps> = ({ title, caption, img, onClick, className, imgSize="medium", rounded=true, useSkeleton = false, children }) => {
    const imgJsx = typeof img === 'string' ? <StyledUserAvatar rounded={rounded} avatar={img} size={imgSize} /> : <StyledImgWrapper>{img}</StyledImgWrapper>;
    return <StyledWrapper onClick={onClick} className={className}>
            {!!img && imgJsx}
            <StyledContent haveAvatar={!!img}>
                <StyledTitle useSkeleton={useSkeleton}>{title}</StyledTitle>
                <StyledCaption useSkeleton={useSkeleton}>{caption}</StyledCaption>
            </StyledContent>
            {children}
    </StyledWrapper>
};