import { useMe } from "@/providers/MeProvider/Me.provider";
import React, { FC, ReactNode } from "react";
import { TextSkeletonProps } from "./TextSkeleton.type";
import { StyledBg, StyledSpan } from "./TextSkeleton.styled";

export const TextSkeleton: FC<TextSkeletonProps> = ({ children, className, useSkeleton = true, isExpired = false }) => {
    const { user } = useMe();
    const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED';
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    const skeletonState = useSkeleton && (isSubscriptionExpired || (isExpired && isIndividual));

    const renderSkeleton = (node: ReactNode): ReactNode => {
        if (typeof node === 'string' || typeof node === 'number') {
            const nodeStr = typeof node === 'string' ? node : node.toString();
            return nodeStr.split(' ').map((word, index) => (
                <React.Fragment key={index}>
                    <StyledSpan >
                        {word}
                        <StyledBg />
                    </StyledSpan>
                    &nbsp;
                </React.Fragment>
            ));
        } else if (React.isValidElement(node)) {
            return React.cloneElement(node, {
                ...node.props,
                children: React.Children.map(node.props.children, renderSkeleton),
            });
        } else {
            return node;
        }
    };

    return (
        <div className={className}>
            {skeletonState ? React.Children.map(children, renderSkeleton) : children}
        </div>
    );

};