import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastService } from 'service/ToastService';
import { ConnectionNotificationDialog } from '@/components/ConnectionNotificatiionDialog/ConnectionNotificatiionDialog.component';
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from "../../../service/helpers";
import { useMe } from 'providers/MeProvider/Me.provider';
import { generateAppNameFromType } from './ManageIntegrations.utils';
import { STAGE_PARAM_AFTER_CONNECT_VALUE, STAGE_PARAM_ERROR_VALUE, STAGE_PARAM_NAME, initialIntegrationStatus } from './ManageIntegrations.const';
import { useRouter } from 'providers/Router/Router.provider';
import { StyledActions, StyledActionsContainer, StyledOnboardingLayout, StyledSectionHeader } from './ManageIntegrations.styled';
import { EventTypes, useAmplitude } from '@/service/TrackingService';
import { useChannels } from '@/providers/v3/Channels/Channels.provider';
import { useConfirm } from '@/hooks/v3/UseConfirm/UseConfirm.hook';
import { Tip } from './components/Tip/Tip.component';
import { LoaderLayout } from '@/components/v3/Layouts/LoaderLayout/LoaderLayout.component';
import { CategotyLayout } from './components/CategotyLayout/CategotyLayout.component';
import { Button } from '@/components/v3/Fields/Button/Button.component';
import { Integration } from '@/components/v3/Modals/ManageIntegrationsDialog/components/ManageIntegrationsContent/components/Integration/Integration.component';

export const ManageIntegrations = () => {
  const router = useRouter();
  const search = useLocation().search;
  const stage = new URLSearchParams(search).get(STAGE_PARAM_NAME);
  const { user, loadUser } = useMe();
  const servicesIntegrationStatus = useMemo(() => {
    return {
      isSlackActive: !!user?.integrations?.slack.active,
      isGoogleDriveActive: !!user?.integrations?.google_drive.active,
      googleDriveAccounts: user?.integrations?.google_drive.accounts,
      isGmailActive: !!user?.integrations?.gmail.active,
      gmailAccounts: user?.integrations?.gmail.accounts,
      isAtlassianActive: !!user?.integrations?.atlassian.active,
      isAsanaActive: !!user?.integrations?.asana.active,
      isGitHubActive: !!user?.integrations?.github.active,
      isClickUpActive: !!user?.integrations?.click_up.active,
      isTrelloActive: !!user?.integrations?.trello.active,
      isFigmaActive: !!user?.integrations?.figma.active,
      isDropboxActive: !!user?.integrations?.dropbox_business.active,
      isTeamsActive: !!user?.integrations?.microsoft_teams.active,
      isAzureMailActive: !!user?.integrations?.outlook.active,
      outlookAccounts: user?.integrations?.outlook.accounts,
      isNotionActive: !!user?.integrations?.notion.active,
      isGoogleCalendarActive: !!user?.integrations?.google_calendar.active,
      googleCalendarAccounts: user?.integrations?.google_calendar.accounts,
      isAtLeastOneServiceConnected:
        user?.integrations?.slack.active ||
        user?.integrations?.google_drive.active ||
        user?.integrations?.gmail.active ||
        user?.integrations?.atlassian.active ||
        user?.integrations?.asana.active ||
        user?.integrations?.github.active ||
        user?.integrations?.click_up.active ||
        user?.integrations?.trello.active ||
        user?.integrations?.figma.active ||
        user?.integrations?.dropbox_business.active ||
        user?.integrations?.microsoft_teams.active ||
        user?.integrations?.outlook.active ||
        user?.integrations?.notion.active ||
        user?.integrations?.google_calendar.active
    }
  }, [user?.integrations]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  if (user?.isOnboarded && stage !== STAGE_PARAM_AFTER_CONNECT_VALUE) {
    router.push({
      name: 'forYou'
    });
  }

  useEffect(() => {
    if (stage === STAGE_PARAM_ERROR_VALUE) {
      ToastService.showToast(
        'error',
        'Some error occurred while connecting the service, please try again or contact us if error occurred more than once'
      );
    }
  }, [stage]);


  const { update: updateChannels } = useChannels();
  const { component, confirm } = useConfirm({
    title: 'Disconnect integration',
    content: <>
      <StyledSectionHeader title="Are you sure you want to disconnect this integration?" />
      <div>
        Synchronisation of all the resources and updates from this app will be stopped for all users. The data that was already synchronised will remain. You can delete all integrations data using the block at the bottom of the “Manage integrations” modal. 
      </div>
    </>,
    submitButtonProps: {
      children: 'Disconnect',
      variant: 'error'
    },
    cancelButtonProps: {
      children: 'Cancel',
      variant: 'primary'
    }
  });
  const disconnectHandler = (type: string | undefined, email?: string) => {
    if (!user?.isAdmin) {
      return;
    }
    confirm(async (
      result
    ) => {
      if (result) {
        let url = `${envUrl}/sense-facade/v1/integrations?userId=${user?.id}&type=${type}`;
        if (email) {
          url += `&email=${email}`;
        }
        setIsLoading(true);
        await HttpClient.delete(url)
          .then(response => response.data)
          .then(data => {
            HttpClient.get(`${envUrl}/sense-facade/v1/users/${user?.id}/info`)
              .then(response => response.data)
              .then(async data => {
                updateChannels();
                await loadUser();
                setIsLoading(false);
              })
            ToastService.showToast('success', `${generateAppNameFromType(type)} was disconnected`);
          })
          .catch(error => {
            ToastService.showToast('error', 'Some error occurred! Please try again later!');
            setIsLoading(false);
          });
      }
    });
  };

  const handleSynchronization = () => {
    const activeIntegrations = Object.entries(servicesIntegrationStatus)
      .filter(([key, value]) => {
        if (key !== 'isAtLeastOneServiceConnected')
          return value === true
      }).map(([key]) => key).map((integration) => {
        if (integration.includes('Slack')) return 'slack'
        else if (integration.includes('Atlassian')) return 'atlassian'
        else if (integration.includes('Gmail')) return 'gmail'
        else if (integration.includes('GoogleDrive')) return 'google_drive'
        else if (integration.includes('Dropbox')) return 'dropbox_business'
        else if (integration.includes('Teams')) return 'microsoft_teams'
        else if (integration.includes('AzureMail')) return 'outlook'
        else if (integration.includes('GoogleCalendar')) return 'google_calendar'
        else if (integration.includes('Notion')) return 'notion'
        else if (integration.includes('Asana')) return 'asana'
        else if (integration.includes('GitHub')) return 'github'
        else if (integration.includes('ClickUp')) return 'click_up'
        else if (integration.includes('Trello')) return 'trello'
        else if (integration.includes('Figma')) return 'figma'
      });
    activeIntegrations.forEach(integration => {
      HttpClient.get(`${envUrl}/sense-facade/v1/integrations/synchronization?userId=${user?.id}&type=${integration}`).then(response => {
      }).catch(() => {
        ToastService.showToast('error', 'Some error occurred! Please try again later!')
      }
      )
    })
  }
  const connectedAccounts = useMemo((): string[] => {
    const accounts: string[] = [];
    for (const [key, value] of Object.entries(servicesIntegrationStatus)) {
      if (!Array.isArray(value)) {
        !!value && accounts.push(key)
      }
    }
    return accounts;
  }, [servicesIntegrationStatus]);
  const trackEvent = useAmplitude();

  const finishHandler = async () => {
    setIsLoading(true);
    await HttpClient.patch(`${envUrl}/organization-service/v1/users/${user?.id}`, {
      onboarded: true,
    }).then(() => {
      loadUser();
    });
    localStorage.setItem('selectedSpace', 'All Spaces');
    router.push({
      name: 'forYou'
    })
    localStorage.removeItem("onboardingStep");
    localStorage.removeItem("plan");
    setIsLoading(false);
  }
  
  return (
    <>
      <StyledOnboardingLayout title="Connect apps">
        <LoaderLayout isLoading={isLoading} transparent>
          <Tip />
          <CategotyLayout title="Documenting">
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isNotionActive}
              icon={'/icons/icon-notion.svg'}
              appName={'Notion'}
              disconnectHandler={disconnectHandler}
              type='notion'
              caption='Pages, Documents, Links, Comments, Tasks, People'
            />
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isAtlassianActive}
              icon="/icons/icon-atlassian.png"
              appName='Atlassian (Jira, Confluence)'
              disconnectHandler={disconnectHandler}
              type='atlassian'
              caption='Projects, Spaces, Tasks, Documents, Pages, Links, Comments, People'
            />
          </CategotyLayout>
          <CategotyLayout title="Project management">
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isAsanaActive}
              icon="/icons/icon-asana.png"
              appName='Asana'
              disconnectHandler={disconnectHandler}
              type='asana'
              caption='Projects, Tasks, Links, Files, Comments, People'
            />
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isClickUpActive}
              icon="/icons/icon-click_up.png"
              appName='ClickUp'
              disconnectHandler={disconnectHandler}
              type='click_up'
              caption='Spaces, Tasks, Documents, Links, Files, Comments, People'
            />
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isAtlassianActive}
              icon="/icons/icon-atlassian.png"
              appName='Atlassian (Jira, Confluence)'
              disconnectHandler={disconnectHandler}
              type='atlassian'
              caption='Projects, Spaces, Tasks, Documents, Pages, Links, Comments, People'
            />
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isTrelloActive}
              icon="/icons/icon-trello.png"
              appName='Trello'
              disconnectHandler={disconnectHandler}
              type='trello'
              caption='Boards, Tasks, Links, Documents, Files, People'
            />
          </CategotyLayout>
          <CategotyLayout title="Storage">
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isGoogleDriveActive}
              icon="/icons/icon-drive.png"
              appName='Google Drive'
              disconnectHandler={disconnectHandler}
              type='google_drive'
              caption='Files, Documents'
            />
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isTeamsActive}
              icon='/icons/icon-microsoft.png'
              appName='Microsoft (Teams, SharePoint)'
              disconnectHandler={disconnectHandler}
              type='microsoft_teams'
              caption='Teams, Discussions, Links, Files, Documents, Comments, People'
            />
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isDropboxActive}
              icon='/icons/icon_dropbox.png'
              appName='Dropbox for Business'
              disconnectHandler={disconnectHandler}
              type='dropbox_business'
              caption='Files, Documents'
            />
          </CategotyLayout>
          <CategotyLayout title="Emails">
            <Integration
              icon={"/icons/icon-gmail.png"}
              servicesIntegrationStatus={servicesIntegrationStatus.isGmailActive}
              appName='Gmail'
              disconnectHandler={disconnectHandler}
              type='gmail'
              caption='Emails, Contacts, Links, Attachments'
            />
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isAzureMailActive}
              icon='/icons/icon-outlook.png'
              appName='Outlook'
              disconnectHandler={disconnectHandler}
              accounts={servicesIntegrationStatus.outlookAccounts}
              type='outlook'
              caption='Emails, Events, Contacts, Links, Attachments'
            />
          </CategotyLayout>
          <CategotyLayout title="Calendars">
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isAzureMailActive}
              icon='/icons/icon-outlook.png'
              appName='Outlook'
              disconnectHandler={disconnectHandler}
              accounts={servicesIntegrationStatus.outlookAccounts}
              type='outlook'
              caption='Emails, Events, Contacts, Links, Attachments'
            />
            <Integration
              icon='/icons/meeting-icon.svg'
              appName='Google Calendar'
              servicesIntegrationStatus={servicesIntegrationStatus.isGoogleCalendarActive}
              disconnectHandler={disconnectHandler}
              type='google_calendar'
              accounts={servicesIntegrationStatus.googleCalendarAccounts}
              caption='Events, Links, Contacts'
            />
          </CategotyLayout>
          <CategotyLayout title="Communication">
            <Integration
              icon='/icons/icon-slack.png'
              appName='Slack'
              servicesIntegrationStatus={servicesIntegrationStatus.isSlackActive}
              disconnectHandler={disconnectHandler}
              type='slack'
              caption='Channels, Discussions, Links, Files, People'
            />
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isTeamsActive}
              icon='/icons/icon-microsoft.png'
              appName='Microsoft (Teams, SharePoint)'
              disconnectHandler={disconnectHandler}
              type='microsoft_teams'
              caption='Teams, Discussions, Links, Files, Documents, Comments, People'
            />
          </CategotyLayout>
          <CategotyLayout title="Pull requests">
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isGitHubActive}
              icon="/icons/icon-github.png"
              appName='GitHub'
              disconnectHandler={disconnectHandler}
              type='github'
              caption='Repositories, Pull requests, Issues, Links, Comments, Files, People'
            />
          </CategotyLayout>
          <CategotyLayout title="Design">
            <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isFigmaActive}
              icon="/icons/icon-figma.png"
              appName='Figma'
              disconnectHandler={disconnectHandler}
              type='figma'
              caption='Designs, People'
            />
          </CategotyLayout>
        </LoaderLayout>
      </StyledOnboardingLayout>
      <StyledActions>
        <StyledActionsContainer>
          {servicesIntegrationStatus.isAtLeastOneServiceConnected && <Button
            onClick={() => {
              trackEvent(EventTypes.ONBOARDING_CONNECT_APPS, {
                apps: connectedAccounts
              })
              handleSynchronization()
              // router.push({
              //   name: 'invite'
              // });
              // localStorage.setItem("onboardingStep", "invite");
              finishHandler();
            }}
            // disabled={!servicesIntegrationStatus.isAtLeastOneServiceConnected}
            variant='secondary'
          >
            Start integration
          </Button>}
          {!servicesIntegrationStatus.isAtLeastOneServiceConnected &&<Button
            onClick={() => {
              // router.push({
              //   name: 'invite'
              // });
              // localStorage.setItem("onboardingStep", "invite")
              finishHandler();
            }}
            // disabled={!servicesIntegrationStatus.isAtLeastOneServiceConnected}
            variant="secondary"
          >
            Continue
          </Button>}
        </StyledActionsContainer>
      </StyledActions>
      <ConnectionNotificationDialog />
      {component}
    </>
  );
};
