import { TextSkeleton } from "@/components/v3/Skeleton/TextSkeleton/TextSkeleton.component";
import { styled } from "@mui/material";

export const PesonWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`
export const PesonName = styled(TextSkeleton)`
    color: #202020;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 8px;
`
export const PesonAction = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-left: 8px;
`