import { FC, useState } from "react";
import {
    StyledButton,
    StyledCard,
    StyledDivider,
    StyledInput,
    StyledLoginPage,
    StyledSubmitButton,
    StyledTitle
} from "./Login.styled";
import { useRouter } from "@/providers/Router/Router.provider";
import { GoogleLoginButton } from "./components/GoogleLoginButton/GoogleLoginButton.component";
import { ErrorsType } from "./Login.type";
import { useHistory } from "react-router-dom";
import { LoginFetch } from "@/axios/OrganizationService/Users/Users.api";
import { ToastService } from "@/service/ToastService";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { LoginAxiosResponseSuccessType } from "@/axios/OrganizationService/Users/Types/Login/Login.type";
import { UserMeAxiosResponseSuccessType } from "@/axios/SenseFacade/Users/Types/GetMe/GetMe.type";
import { GetMeFetch } from "@/axios/SenseFacade/Users/Users.api";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { Terms } from "./components/Terms/Terms.component";
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from "@/service/helpers";

export const LoginPage : FC = () => {
    const router = useRouter();
    const history = useHistory();
    const { login, loadUser } = useMe();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errors, setErrors] = useState<ErrorsType>({});
    const trackEvent = useAmplitude();
    const validate = () => {
        let tempErrors: ErrorsType = {};
        if (!email) tempErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(email)) tempErrors.email = "Invalid email format";
        if (!password) tempErrors.password = "Password is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };


    const submit = async () => {
        if (validate()) {
          setIsLoading(true);
          await LoginFetch({
              email: email,
              password: password,
            }).then((response: LoginAxiosResponseSuccessType) => {
              if (response.data.otpEnabled === false) {
                login(response.data.accessToken, async () => {
                  await GetMeFetch().then(async (res: UserMeAxiosResponseSuccessType) => {
                    const { accountReady, emailConfirmed, isOnboarded } = res.data.user;
                    if (!isOnboarded) {
                      await HttpClient.put(`${envUrl}/organization-service/v1/payment/subscription`, {
                        name: 'INDIVIDUAL',
                        userId: res.data.user.id,
                      });
                      // localStorage.setItem("onboardingStep", "manageIntegrations");
                      // router.push({
                      //     name: 'manageIntegrations'
                      // })
                    }
                    if (!res.data.user.firstLogin) {
                      HttpClient.patch(`${envUrl}/organization-service/v1/users/${res.data.user.id}`, {
                        firstLogin: true,
                      });
                      trackEvent(EventTypes.FIRST_LOGIN)
                    }
                    await loadUser()
                    
                    const pathFromLink = localStorage.getItem('pathFromLink');
      
                    if (!emailConfirmed) {
                      router.push({
                        name: 'linkConfirmation',
                        query: router.query
                      })
                      return;
                    }
                    else if (!accountReady && !isOnboarded) {
                      localStorage.setItem('selectedSpace', 'All Spaces');
                      await HttpClient.patch(`${envUrl}/organization-service/v1/users/${res.data.user.id}`, {
                        onboarded: true,
                      });

                      return;
                    }
                    else if (!isOnboarded) {
                      // start disable onboarding
                      // if (res.data.user.paymentPlan.name === 'PRO') {
                      //   router.push({
                      //     name: 'manageIntegrations'
                      //   })
                      //   localStorage.setItem("onboardingStep", "manage-integrations");
                      // } else {
                      //   router.push({
                      //     name: 'selectPlan'
                      //   })
                      //   localStorage.setItem("onboardingStep", "select-plan");
                      // }
                      await HttpClient.patch(`${envUrl}/organization-service/v1/users/${res.data.user.id}`, {
                        onboarded: true,
                      });
                      if (router.query.redirectUrl) {
                        window.location.href = `${window.location.origin}${router.query.redirectUrl}`;
                      } else {
                        router.push({
                          name: 'forYou',
                          query: router.query
                        })
                      }
                      return;
                      // end disable onboarding
                    } else {
                      const path = pathFromLink && pathFromLink !== '/login' ? pathFromLink : '/for-you';
                      localStorage.removeItem('pathFromLink');
                      history.push(path);
                    }
                    const selectedSpace = localStorage.getItem('selectedSpace');
                    if (!selectedSpace) {
                      localStorage.setItem('selectedSpace', 'All Spaces')
                    }
                  })
      
                });
              } else {
                router.push({
                  name: 'twoFactorAuthntication',
                  params: {
                    secret: response.data.secret,
                  }
                })
              }
            }).catch(error => {
              ToastService.showToast('error', error?.response?.data?.errorMessage);
            });
            setIsLoading(false);
        }
    };
    return <StyledLoginPage transparent isLoading={isLoading}>
      <div>
        <StyledTitle>Login to Sense</StyledTitle>
        <StyledCard>
          <GoogleLoginButton />
          <div>
            <StyledInput error={errors.email} value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
          </div>
          <div>
            <StyledInput error={errors.password} value={password} onChange={(e) => setPassword(e.target.value)} label="Password" type="password" />
          </div>
          <StyledSubmitButton size="large" onClick={submit}>Login</StyledSubmitButton>
          <StyledDivider />
          <StyledButton size="large" onClick={() => router.push({
            name: 'signup'
          })} variant="secondary">Create account</StyledButton>
        </StyledCard>
        <StyledButton onClick={() => router.push({
          name: 'resetPassword'
        })} variant="tertiary">Forgot password</StyledButton>
      </div>
      <Terms />
    </StyledLoginPage>
};