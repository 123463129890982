import { styled } from "@mui/material";

export const StyledContainer = styled('div')`
    padding-left: 12px;
    padding-right: 12px;
`;
export const StyledWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isActive',
})<{isActive: boolean}>`
    padding: 4px 0px 16px 0px;
    border-bottom: ${(props) => props.isActive ? '2px solid #202020' : '2px solid transparent'};
    cursor: pointer;
    transition: 0.3s ease-in-out;
    color: ${(props) => props.isActive ? '#202020' : '#636363'};
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;