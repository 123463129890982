import { FC } from "react";
import { Header } from "./components/Header/Header.component";
import { MainLayout } from "@/components/v3/Layouts/MainLayout/MainLayout.component";
import { Sidebar } from "./components/Sidebar/Sidebar.component";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { Tabs } from "./components/Tabs/Tabs.component";
import { Updates } from "./components/Updates/Updates.component";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { UpdateSpaceFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { ToastService } from "@/service/ToastService";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { EmptyState } from "./components/EmptyState/EmptyState.component";
import { AddInSpaceProvider } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { AllResources } from "./components/AllResources/AllResources.component";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { Emails } from "./components/Emails/Emails.component";
import { Activities } from "./components/Activities/Activities.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { EditSpaceModal } from "./components/EditSpaceModal/EditSpaceModal.component";
// import { KeyResources } from "./components/KeyResources/KeyResources.component";
import { Channels } from "./components/Channels/Channels.component";
import { People } from "./components/People/People.component";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { CreateNoteDrawer } from "@/providers/v3/SpacePageCommon/components/CreateNoteDrawer/CreateNoteDrawer.component";

export const SpaceContent: FC = () => {
    const {
        space,
    } = useSpacePageCommon();
    const {
        listOfRecentActivities,
        listOfLastRecentActivities,
        isFetchingListOfLastRecentActivities,
        listOfEmails,
        activeTab,
        update
    } = useSpacePage();
    const { isShared } = useIsShared();
    const { loadSpaces } = useSpaces();
    const shoudRenederMainSidebar = useRenederAbove('medium');
    const updateEmoji = (emoji: string | null) => {
        !!space && UpdateSpaceFetch(space.id, {
          ...space,
          emoji: emoji,
        }).then(() => {
          update();
          loadSpaces();
          ToastService.showToast('success', 'Space emoji has been changed!');
        }).catch((err) => {
            ToastService.showToast('error', 'Some error occurred');
        });
    };
    const isEmptySpace = !listOfLastRecentActivities.length && !isFetchingListOfLastRecentActivities
    return <AddInSpaceProvider onUpdate={update}>
        <MainLayout sidebar={shoudRenederMainSidebar ? <Sidebar onUpdate={update} /> : undefined}>
            {!!space && <Header
                showAddButton={!!listOfRecentActivities.length || !!listOfLastRecentActivities.length || !!listOfEmails}
                space={space}
                onNewEmoji={updateEmoji}
                disabledShare={isEmptySpace}
            />}
            <EmptyStateLayoyt
                value={isEmptySpace}
                emptyNode={<EmptyState />}
            >
                <Tabs />
                {activeTab === 'UPDATES' && <Updates />}
                {activeTab === 'ALL_RESOURCES' && <AllResources />}
                {/* {activeTab === 'EMAILS' && <Emails />} */}
                {activeTab === 'ACTIVITIES' && <Activities />}
                {/* {activeTab === 'KEY_RESOURCES' && <KeyResources onUpdate={update} />} */}
                {activeTab === 'CHANNELS' && <Channels />}
                {activeTab === 'PEOPLE' && <People />}
            </EmptyStateLayoyt>
            {!!space && !isShared &&  <EditSpaceModal space={space} onUpdate={update} />}
        </MainLayout>
        <CreateNoteDrawer onUpdate={update} />
    </AddInSpaceProvider>
};