import { FC } from "react";
import { AppProps } from "./App.type";
import { StyledWrapper } from "./App.styled";
import { ListItem } from "@/components/v3/List/ListItem/ListItem.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { openConectAppLink } from "@/utils/v3/OpenConectAppLink/OpenConectAppLink.util";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { AppIcon } from "@/components/v3/Other/AppIcon/AppIcon.component";
import { useRouter } from "@/providers/Router/Router.provider";
import { ButtonProps } from "../../Fields/Button/Button.type";

export const App: FC<AppProps> = ({
    app,
    buttonProps = {},
}) => {
  const { user } = useMe();
  const router = useRouter();
  const connectHandler = () => {
      if (app.title === "Asana") {
        openConectAppLink('asana', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === "Github") {
        openConectAppLink('github', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === "ClickUp") {
        openConectAppLink('click_up', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === "Trello") {
        openConectAppLink('trello', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === "Figma") {
        openConectAppLink('figma', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === "Slack") {
        openConectAppLink('slack', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === "Jira" || app.title === "Confluence") {
        openConectAppLink('atlassian', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === 'Microsoft Teams') {
        openConectAppLink('microsoft_teams', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === 'Sharepoint') {
        openConectAppLink('microsoft_teams', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === 'Notion') {
        openConectAppLink('notion', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === 'Gmail') {
        openConectAppLink('gmail', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === 'Google Drive') {
        openConectAppLink('google_drive', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === 'Outlook') {
        openConectAppLink('outlook', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === 'Dropbox for Business') {
        openConectAppLink('dropbox_business', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      } else if (app.title === 'Google Calendar') {
        openConectAppLink('google_calendar', user?.id, {
          addTab: router.query.tab as string,
          spaceId: router.params.spaceId as string
        })
      }
  }
  const defaultButtonProps: ButtonProps = {
    variant: 'primary',
    onClick: connectHandler
  };
  const finalButtonProps = { ...defaultButtonProps, ...buttonProps };
  return <StyledWrapper>
      <ListItem
          title={app.title}
          img={<AppIcon application={app.application} />}
      />
      {!app.isConnected && <Button {...finalButtonProps}>Connect</Button>}
  </StyledWrapper>
};