import { FC } from 'react';
import { HttpClient } from '@/axios/HttpClient';
import { useMe } from 'providers/MeProvider/Me.provider';
import { InvitedUsersListItemProps } from './InvitedUsersListItem.type';
import {
  StyledPerson,
  StyledRemoveButton,
} from './InvitedUsersListItem.styled';
import { DeleteRoleFetch, UpdateRoleFetch } from '@/axios/OrganizationService/Users/Users.api';
import { Select } from '@/components/v3/Fields/Select/Select.component';
import { useConfirm } from '@/hooks/v3/UseConfirm/UseConfirm.hook';
import { envUrl } from '@/service/helpers';
import { ToastService } from '@/service/ToastService';
import { useRouter } from '@/providers/Router/Router.provider';
import { StyledUserAvatar } from '@/components/v3/List/Person/Person.styled';

export const InvitedUsersListItem: FC<InvitedUsersListItemProps> = ({ user, setIsLoading }: InvitedUsersListItemProps) => {
  const { confirm, component } = useConfirm({
    title: "Remove User",
    content: <>
      Removing user will erase all their data on Sense. They will not be able to use their Sense account anymore.
      The billing will be updated in the end of your current billing cycle.
    </>,
    cancelButtonProps: {
      children: "Keep user",
      variant: 'primary',
    },
    submitButtonProps: {
      children: "Remove user",
      variant: 'error'
    },
  });
  const { user: userMe, loadInvitedUsers } = useMe();
  const router = useRouter();
  const isOnboarding = router.type.includes('onboarding');
  const removeHandler = () => {
    confirm(async (result) => {
      if (!!result) {
        // if (isOnboarding) {
        //   // setUsers((prev) => {
        //   //   const newState = prev.filter((el: any) => el.email !== user.email)
        //   //   return newState
        //   // })
        // } else {
          setIsLoading(true);
          user.fullName ?
            await HttpClient.delete(`${envUrl}/organization-service/v1/users/${user.id}`)
              .then(response => response.data)
              .then(data => {
                ToastService.showToast('success', 'User successfully removed!');
              })
              .catch(error => {
                ToastService.showToast('error', 'Some error occurred! Please try re-send email!');
              }) :
            await HttpClient.delete(`${envUrl}/organization-service/v1/users/invites`, {
              data: {
                organizationId: userMe?.organizationId,
                emails: [user.email],
              }
            })
              .then(response => response.data)
              .then(data => {
                ToastService.showToast('success', 'Email successfully removed!');
              })
              .catch(error => {
                ToastService.showToast('error', 'Some error occurred! Please try re-send email!');
              })
          await loadInvitedUsers();
          setIsLoading(false);
        // }
      }
    })
  }
  const roleOptions = [
    {
      label: 'Admin',
      value: true
    },
    {
      label: 'Member',
      value: false
    }
  ]
  const onChangeRole = async (val: boolean) => {
    if (!user.id) {
      return;
    }
    if (val) {
      await UpdateRoleFetch(user.id, {
        roleName: 'org_admin',
      }).then(() => {
        ToastService.showToast('success', 'Admin role was assigned to the user');
      })
    } else {
      await DeleteRoleFetch(user.id, {
        roleName: 'org_admin',
      }).then(() => {
        ToastService.showToast('success', 'Member role was assigned to the user');
      })
    }
    loadInvitedUsers();
  }
  return (
    <>
      <StyledPerson
        title={user.email}
        caption={!isOnboarding && (user.status === 'pending' ? user.status : '')}
        img={<StyledUserAvatar size="large"/>}
      >
        {!isOnboarding && <Select
          label="Role"
          value={!!user.isAdmin}
          options={roleOptions}
          onChange={onChangeRole}
          disabled={user.status === 'pending'}
        />}
        <StyledRemoveButton center onClick={removeHandler} variant="tertiary">Remove</StyledRemoveButton>
      </StyledPerson>
      {component}
    </>

  )
}