import { FC, useMemo } from "react";
import { StyledActivityMessage, StyledActivityMessageWrapper, StyledCalendarIcon, StyledComment, StyledSectionHeader, StyledTitleText, StyledWrapper } from "./Activities.styled";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import moment from "moment";
import { GroupLayout } from "@/components/v3/Layouts/GroupLayout/GroupLayout.component";
import { formatGroupDate } from "@/utils/v3/FormatGroupDate/FormatGroupDate.util";
import { ActorTypeWithUser } from "@/utils/AddUsersToRelations/AddUsersToRelations.type";
import { ActivityMessageType } from "@/types/ActivityMessage/ActivityMessage.type";
import { NodeCommentType } from "@/types/NodeComment/NodeComment.type";
import { Actor } from "./components/Actor/Actor.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { ConnectApps } from "@/components/v3/Sections/ConnectApps/ConnectApps.component";
import { useShowMore } from "@/hooks/v3/UseShowMore/UseShowMore.hook";
import { Button } from "@/components/v3/Fields/Button/Button.component";

export const Activities: FC = () => {
    const {
        node,
        relationships,
        slackMessages,
        teamsMessages,
        atlassianTaskComments,
        asanaTaskComments,
        githubIssueComments,
        githubPullComments,
        clickUpTaskComments,
        trelloCardComments,
        figmaTaskComments,
        nodeUsers,
    } = useNode();
    const messages = useMemo(() => {
        return [
            ...slackMessages,
            ...teamsMessages,
            ...atlassianTaskComments,
            ...asanaTaskComments,
            ...githubIssueComments,
            ...githubPullComments,
            ...clickUpTaskComments,
            ...trelloCardComments,
            ...figmaTaskComments
        ]
    }, [asanaTaskComments, atlassianTaskComments, clickUpTaskComments, figmaTaskComments, githubIssueComments, githubPullComments, slackMessages, teamsMessages, trelloCardComments]);
    // const actors = (relationships?.actors || []).filter((el) => !!el.properties.happenedAt).map((actor) => {
    const actors = (relationships?.actors || []).map((actor) => {
        if ((actor.actionType==="CREATED_BY" || actor.actionType==="CREATOR") && node) {
            
            return {
                ...actor,
                properties: {
                    happenedAt: node.createdAt
                }
            }
        }
        return {
            ...actor,
            properties: {
                ...actor.properties,
                happenedAt: actor.properties.happenedAt
            }
        }
    });
    const activitiesWithComments = [...messages, ...(node?.comments || []), ...actors].sort((a, b) => {
        let aTime, bTime;
        if ('createdAt' in a) aTime = moment.utc(a.createdAt).local().toDate();
        else if ('messageTs' in a) aTime = moment.unix(parseInt(a.messageTs)).toDate();
        else if ('properties' in a && a.properties.happenedAt) aTime = moment.utc(a.properties.happenedAt).local().toDate();

        if ('createdAt' in b) bTime = moment.utc(b.createdAt).local().toDate();
        else if ('messageTs' in b) bTime = moment.unix(parseInt(b.messageTs)).toDate();
        else if ('properties' in b && b.properties.happenedAt) bTime = moment.utc(b.properties.happenedAt).local().toDate();
        if (!!aTime && !!bTime) {
            return bTime.getTime() - aTime.getTime();
        }
        return 1;
    });
    const { items, showMore, setShowMore } = useShowMore({ items: activitiesWithComments });
    
    const groups = useMemo(() => {
        const groupedByDay = new Map();
        const noDate: (ActivityMessageType| NodeCommentType | ActorTypeWithUser)[] = [];
        items.forEach(activity => {
            let activityDate;
            if ('createdAt' in activity) activityDate = moment.utc(activity.createdAt).local().startOf('day');
            else if ('messageTs' in activity) activityDate = moment.unix(parseInt(activity.messageTs)).startOf('day');
            else if ('properties' in activity && activity.properties.happenedAt) activityDate = moment.utc(activity.properties.happenedAt).local().startOf('day');
            if (!!activityDate) {
                const dateKey = activityDate.format('YYYY-MM-DD');
                if (!groupedByDay.has(dateKey)) {
                    groupedByDay.set(dateKey, []);
                }
                groupedByDay.get(dateKey).push(activity);
            } else {
                noDate.push(activity);
                console.warn('No date found for activity', activity);
            }
        });
        const newVal = Array.from(groupedByDay.values()) as (ActivityMessageType| NodeCommentType | ActorTypeWithUser)[][];
        !!noDate.length && newVal.push(noDate);
        return newVal;
    }, [items]);
    
    const groupsJsx = groups.map((group, i) => {
        const itemsJsx = group.map((activityOrCommentOrActor: any, j) => {
            if ('createdAt' in activityOrCommentOrActor) {
                const user = nodeUsers.find((user) => user.id === activityOrCommentOrActor.userId);
                return <StyledActivityMessageWrapper key={j}>
                    <Actor actor={{
                        actionType: 'COMMENTED_BY',
                        properties: {
                            happenedAt: activityOrCommentOrActor.createdAt
                        },
                        user: user
                    }} />
                    <StyledComment comment={activityOrCommentOrActor} users={nodeUsers} />
                </StyledActivityMessageWrapper>
            } else if ('properties' in activityOrCommentOrActor) {
                return <Actor actor={activityOrCommentOrActor} key={j} />
            } else {
                return <StyledActivityMessageWrapper key={j}>
                    <Actor actor={{
                        actionType: 'COMMENTED_BY',
                        properties: {
                            happenedAt: activityOrCommentOrActor?.messageTs ? moment.unix(parseInt(activityOrCommentOrActor.messageTs)).toString() : undefined
                        },
                        user: {
                            id: '',
                            avatar: activityOrCommentOrActor.avatar,
                            firstName: activityOrCommentOrActor.userName,
                        }
                    }} />
                    <StyledActivityMessage activityMessage={activityOrCommentOrActor} users={nodeUsers} />
                </StyledActivityMessageWrapper>
            }
        });
        const getGroupDate = () => {
            const item = group[0];
            if (!item) {
                return;
            }
            if ('createdAt' in item) return formatGroupDate(item.createdAt);
            else if ('messageTs' in item) return formatGroupDate(moment.unix(parseInt(item.messageTs)).toDate());
            else if ('properties' in item && item.properties.happenedAt) return formatGroupDate(item.properties.happenedAt);
        }
        const dateJsx = getGroupDate();
        return <GroupLayout
            key={i}
            title={dateJsx && <>
                <StyledCalendarIcon />
                <StyledTitleText>{getGroupDate()}</StyledTitleText>
            </> }
        >
            {itemsJsx}
        </GroupLayout>
    });
    const isEmpty = !groupsJsx.length;
    
    return <>
        <EmptyStateLayoyt value={isEmpty} emptyNode={<ConnectApps
            title="Get all updates for this resource automatically"
            description="Connect apps to automatically get all conversation, activities, and other updates made for this resource"
            applications={['Slack', 'MicrosoftTeams', 'Asana', 'Jira']}
        />}>
            <StyledWrapper>
                <StyledSectionHeader title="Activities" />
                {groupsJsx}
                {!showMore && activitiesWithComments.length > items.length && <Button variant='tertiary' onClick={() => setShowMore(true)}>Show all</Button>}
            </StyledWrapper>
        </EmptyStateLayoyt>
    </>;
};