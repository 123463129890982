import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { NodeContextType, NodeNeighbourWrapperType } from './Node.type';
import { NodeNeighboursDrawer } from './components/NodeNeighboursDrawer/NodeNeighboursDrawer.component';
import { useMe } from 'providers/MeProvider/Me.provider';
import { GetNodeFetch } from "@/axios/AiService/Node/Node.api";
import {
    GetSpaceNodeFetch as GetPublicSpaceNodeFetch,
    GetNodeFetch as GetPublicNodeFetch
} from "@/axios/SenseFacade/Public/Public.api";
import { NodeAxiosResponseSuccessType, NodeType } from "@/axios/AiService/Node/Types/Get/Get.type";
import { NodeAxiosResponseSuccessType as PublicNodeAxiosResponseSuccessType } from "@/axios/SenseFacade/Public/Types/GetNode/GetNode.type";
import { GetMessagesFetch as GetSlackMessagesFetch } from "@/axios/SlackIntegrationService/Messages/Messages.api";
import { GetMessagesFetch as GetTeamsMessagesFetch } from "@/axios/AzureService/Messages/Messages.api";
import { MessagesAxiosResponseSuccessType as SlackMessagesAxiosResponseSuccessType, SlackMessageType } from "@/axios/SlackIntegrationService/Messages/Types/Get/Get.type";
import { MessagesAxiosResponseSuccessType as TeamsMessagesAxiosResponseSuccessType, TeamsMessageType } from "@/axios/AzureService/Messages/Types/Get/Get.type";
import { GetNodeNeighboursFetch } from "@/axios/AiService/NodeNeighbours/NodeNeighbours.api";
import { NodeNeighboursAxiosResponseSuccessType } from "@/axios/AiService/NodeNeighbours/Types/Get/Get.type";
import { useRouter } from 'providers/Router/Router.provider';
import { GetTaskComments as GetAtlassianTaskComments } from "@/axios/AtlassianService/Tasks/Tasks.api";
import { GetTaskComments as GetAsanaTaskComments } from "@/axios/AsanaService/Tasks/Tasks.api";
import { GetPullsComments as GetGitHubPullComments } from "@/axios/GiHubService/Pulls/Pulls.api";
import { GetIssuesComments as GetGitHubIssueComments } from "@/axios/GiHubService/Issues/Issues.api";
import { GetTaskComments as GetClickUpTaskComments } from "@/axios/ClickUpService/Tasks/Tasks.api";
import { GetCardComments as GetTrelloCardComments } from "@/axios/TrelloService/Cards/Cards.api";
import { GetFileComments as GetFigmaFileComments } from "@/axios/FigmaService/Files/Files.api";
import {
    TaskCommentType as AtlassianTaskCommentType,
    TaskCommentsAxiosResponseSuccessType as AtlassianTaskCommentsAxiosResponseSuccessType
} from "@/axios/AtlassianService/Tasks/Types/GetComments/GetComments.type";
import {
    TaskCommentType as AsanaTaskCommentType,
    TaskCommentsAxiosResponseSuccessType as AsanaTaskCommentsAxiosResponseSuccessType
} from "@/axios/AsanaService/Tasks/Types/GetComments/GetComments.type";
import {
    PullCommentType as GitHubPullCommentType,
    PullCommentsAxiosResponseSuccessType as GitHubPullCommentsAxiosResponseSuccessType
} from "@/axios/GiHubService/Pulls/Types/GetComments/GetComments.type";
import {
    IssueCommentType as GitHubIssueCommentType,
    IssueCommentsAxiosResponseSuccessType as GitHubIssueCommentsAxiosResponseSuccessType
} from "@/axios/GiHubService/Issues/Types/GetComments/GetComments.type";
import {
    TaskCommentType as ClickUpTaskCommentType,
    TaskCommentsAxiosResponseSuccessType as ClickUpTaskCommentsAxiosResponseSuccessType
} from "@/axios/ClickUpService/Tasks/Types/GetComments/GetComments.type";
import {
    CardCommentType as TrelloCardCommentType,
    CardCommentsAxiosResponseSuccessType as TrelloCardCommentsAxiosResponseSuccessType
} from "@/axios/TrelloService/Cards/Types/GetComments/GetComments.type";
import {
    FileCommentType as FigmaFileCommentType,
    FileCommentsAxiosResponseSuccessType as FigmaFileCommentsAxiosResponseSuccessType
} from "@/axios/FigmaService/Files/Types/GetComments/GetComments.type";
import { NodeTypeType } from '@/types/Node/Node.type';
import { UserType } from "@/axios/OrganizationService/Users/Types/Get/Get.type";
import { EventTypes, useAmplitude } from '@/service/TrackingService';
import { useIsShared } from '@/hooks/v3/UseIsShared/UseIsShared.hook';
import { fetchNodeRelations } from '@/utils/FetchNodeRelations/FetchNodeRelations.util';
import { NodeRelationTypeWithUser } from "utils/FetchNodeRelations/FetchNodeRelations.type";
import { addUsersToRelations } from '@/utils/AddUsersToRelations/AddUsersToRelations.util';
import { fetchImportantUsers } from '@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util';
import { GetNodeRelationsFetch } from '@/axios/AiService/NodeRelations/NodeRelations.api';
import { GetNodesFetch } from '@/axios/AiService/Nodes/Nodes.api';
import { NodeRelationType, NodeRelationsAxiosResponseSuccessType } from '@/axios/AiService/NodeRelations/Types/Get/Get.type';
import { NodesAxiosResponseSuccessType } from '@/axios/AiService/Nodes/Types/Get/Get.type';
import { isItemExpired } from '@/utils/v3/IsItemExpired/IsItemExpired.util';
import { filterMailNodes } from '@/utils/v3/FilterSharedMailNodes/FilterSharedMailNodes.util';

const defaultValue: NodeContextType = {
    spaceId: null,
    isFetching: false,
    node: undefined,
    isFollowed: false,
    nodes: [],
    relationships: null,
    slackMessages: [],
    teamsMessages: [],
    atlassianTaskComments: [],
    asanaTaskComments: [],
    githubPullComments: [],
    githubIssueComments: [],
    clickUpTaskComments: [],
    trelloCardComments: [],
    figmaTaskComments: [],
    setActiveNodeId: () => undefined,
    nodeUsers: [],
    update: () => undefined,
    isOpened: false,
    onClose: () => undefined,
};

const NodeContext = React.createContext<NodeContextType>(defaultValue);
export const useNode = () => useContext<NodeContextType>(NodeContext);

export const NodeProvider = (props: { children: React.ReactNode }) => {
    const router = useRouter();
    const nodeQueryId = router.query.node as string;
    const nodeParamsId = router.params.nodeId as string;
    const { isSharedSpace, isSharedNode, isShared } = useIsShared();
    const nodeIdNumber = nodeParamsId ? Number(nodeParamsId) : Number(nodeQueryId);
    const spaceId = router.query.space as string;
    const code = router.params.code as string | undefined;
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [nodeUsers, setNodeUsers] = useState<UserType[]>([]);
    const [nodes, setNodes] = useState<NodeNeighbourWrapperType[]>([]);
    const [node, setNode] = useState<NodeType | undefined>();
    const [relationships, setRelationships] = useState<NodeRelationTypeWithUser | null>(null);
    const [slackMessages, setSlackMessages] = useState<SlackMessageType[]>([]);
    const [teamsMessages, setTeamsMessages] = useState<TeamsMessageType[]>([]);
    const [atlassianTaskComments, setAtlassianTaskComments] = useState<AtlassianTaskCommentType[]>([])
    const [asanaTaskComments, setAsanaTaskComments] = useState<AsanaTaskCommentType[]>([])
    const [githubPullComments, setGitHubPullComments] = useState<GitHubPullCommentType[]>([])
    const [githubIssueComments, setGitHubIssueComments] = useState<GitHubIssueCommentType[]>([])
    const [clickUpTaskComments, setClickUpTaskComments] = useState<ClickUpTaskCommentType[]>([])
    const [trelloCardComments, setTrelloCardComments] = useState<TrelloCardCommentType[]>([])
    const [figmaTaskComments, setFigmaTaskComments] = useState<FigmaFileCommentType[]>([])

    const { users: orgUsers, setUsers: setOrgUsers, followedNodes, user, invitedUsers, confirmedInvitedUsers } = useMe();
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED';
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    const users = isSharedNode ? nodeUsers : orgUsers;
    
    const loadNodeRelationsHandle = useCallback(async (nodeId: number) => {
        if (isSharedSpace || isSharedNode) { return }
        const listOfRelationships = await fetchNodeRelations(users, [nodeId]);
        const nodeRelationships = listOfRelationships[0];
        setRelationships(nodeRelationships);
    }, [isSharedNode, isSharedSpace, users]);

    const loadNoteNodes = useCallback(async () => {
        if (isSharedSpace || isSharedNode) { return }
        await GetNodeRelationsFetch({
            nodeId: Number(nodeIdNumber),
        }).then(async (resRel: NodeRelationsAxiosResponseSuccessType) => {
            await GetNodesFetch({
                nodeIds: (resRel.data as NodeRelationType).relations.map(el => el.nodeId)
            }).then((res: NodesAxiosResponseSuccessType) => {
                const nodes = filterMailNodes(isShared, res.data);
                setNodes(nodes.filter((node) => node.nodeType !== NodeTypeType.MESSAGE).map((node) => {
                    return {
                        node,
                        importantUserIds: []
                    }
                }));
            });
        });
    }, [isShared, isSharedNode, isSharedSpace, nodeIdNumber])
    const loadNodeNeighbours = useCallback(async () => {
        if (isSharedSpace || isSharedNode) { return }
        await GetNodeNeighboursFetch(nodeIdNumber).then(async (res: NodeNeighboursAxiosResponseSuccessType) => {
            const nodes = filterMailNodes(isShared, res.data);
            const nodeIds = nodes.map((el) => el.id)
            const importantUsers = await fetchImportantUsers(nodeIds);
            setNodes(nodes.filter((node) => node.nodeType !== NodeTypeType.MESSAGE).map((node) => {
                const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
                return {
                    node,
                    importantUserIds: importantInfo.userIds
                }
            }));
        });
    }, [isSharedSpace, isSharedNode, nodeIdNumber, isShared])
    const loadNode = useCallback(async (nodeId: number) => {
        setIsFetching(true);
        if (isSharedSpace) {
            if (!code || !nodeId) { return }
            await GetPublicSpaceNodeFetch(nodeId, {
                code
            }).then((res: PublicNodeAxiosResponseSuccessType) => {
                setNode(res.data.node);
                const listOfRelations = addUsersToRelations(users, [res.data.relationships])
                setRelationships(listOfRelations[0]);
                const nodes = filterMailNodes(isShared, res.data.neighbors);
                setNodes(nodes.filter((node) => node.nodeType !== NodeTypeType.MESSAGE).map((node) => {
                    return {
                        node,
                        importantUserIds: []
                    }
                }));
            });
        } else if (isSharedNode) {
            if (!code) { return }
            await GetPublicNodeFetch({
                code
            }).then((res: PublicNodeAxiosResponseSuccessType) => {
                setNode(res.data.node);
                const listOfRelations = addUsersToRelations(res.data.users, [res.data.relationships])
                setRelationships(listOfRelations[0]);
                const nodes = filterMailNodes(isShared, res.data.neighbors);
                setNodes(nodes.filter((node) => node.nodeType !== NodeTypeType.MESSAGE).map((node) => {
                    return {
                        node,
                        importantUserIds: []
                    }
                }));
                const oldUsers = JSON.stringify(users);
                const newUsers = JSON.stringify(res.data.users);
                if (oldUsers !== newUsers) {
                    setNodeUsers(res.data.users)
                    !res.data.users.length && setOrgUsers(res.data.users)
                }
            });
        } else {
            await GetNodeFetch(nodeId).then((res: NodeAxiosResponseSuccessType) => {
                const isNodeExpired = isItemExpired(res.data.createdAt) && !res.data.sampleData && isIndividual;
                if (isNodeExpired) {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            dialog: 'payment',
                            plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                            node: undefined,
                        }
                    });
                } else {
                    setNode(res.data);
                    if (res.data?.nodeType === NodeTypeType.NOTE) {
                        loadNoteNodes();
                    } else {
                        loadNodeNeighbours();
                    }
                }
            });
        }
        setIsFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSharedSpace, isSharedNode, isShared, code, users, loadNoteNodes, loadNodeNeighbours])
    const loadSlackMessages = useCallback(async () => {
        if (isSharedSpace || isSharedNode || !nodeIdNumber) { return }
        await GetSlackMessagesFetch({
            resourceId: nodeIdNumber
        }).then((res: SlackMessagesAxiosResponseSuccessType) => {
            setSlackMessages(res.data.slackMessages);
        });
    }, [nodeIdNumber, isSharedSpace, isSharedNode])
    const loadTeamsMessages = useCallback(async () => {
        if (isSharedSpace || isSharedNode || !nodeIdNumber) { return }
        await GetTeamsMessagesFetch({
            resourceIds: [nodeIdNumber]
        }).then((res: TeamsMessagesAxiosResponseSuccessType) => {
            setTeamsMessages(res.data.teamsMessages);
        });
    }, [nodeIdNumber, isSharedSpace, isSharedNode])
    const loadAtlassianTaskComments = useCallback(async () => {
        if (isSharedSpace || isSharedNode || !nodeIdNumber) { return }
        await GetAtlassianTaskComments({
            nodeIds: [nodeIdNumber]
        }).then((res: AtlassianTaskCommentsAxiosResponseSuccessType) => {
            setAtlassianTaskComments(res.data.comments)
        })
    }, [nodeIdNumber, isSharedSpace, isSharedNode])
    const loadAsanaTaskComments = useCallback(async () => {
        if (isSharedSpace || isSharedNode || !nodeIdNumber) { return }
        await GetAsanaTaskComments({
            nodeIds: [nodeIdNumber]
        }).then((res: AsanaTaskCommentsAxiosResponseSuccessType) => {
            setAsanaTaskComments(res.data.comments)
        })
    }, [nodeIdNumber, isSharedSpace, isSharedNode])
    const loadGitHubPullComments = useCallback(async () => {
        if (isSharedSpace || isSharedNode || !nodeIdNumber) { return }
        await GetGitHubPullComments({
            nodeIds: [nodeIdNumber]
        }).then((res: GitHubPullCommentsAxiosResponseSuccessType) => {
            setGitHubPullComments(res.data.comments)
        })
    }, [nodeIdNumber, isSharedSpace, isSharedNode])
    const loadGitHubIssueComments = useCallback(async () => {
        if (isSharedSpace || isSharedNode || !nodeIdNumber) { return }
        await GetGitHubIssueComments({
            nodeIds: [nodeIdNumber]
        }).then((res: GitHubIssueCommentsAxiosResponseSuccessType) => {
            setGitHubIssueComments(res.data.comments)
        })
    }, [nodeIdNumber, isSharedSpace, isSharedNode])
    const loadClickUpTaskComments = useCallback(async () => {
        if (isSharedSpace || isSharedNode || !nodeIdNumber) { return }
        await GetClickUpTaskComments({
            nodeIds: [nodeIdNumber]
        }).then((res: ClickUpTaskCommentsAxiosResponseSuccessType) => {
            setClickUpTaskComments(res.data.comments)
        })
    }, [nodeIdNumber, isSharedSpace, isSharedNode])
    const loadTrelloCardComments = useCallback(async () => {
        if (isSharedSpace || isSharedNode || !nodeIdNumber) { return }
        await GetTrelloCardComments({
            nodeIds: [nodeIdNumber]
        }).then((res: TrelloCardCommentsAxiosResponseSuccessType) => {
            setTrelloCardComments(res.data.comments)
        })
    }, [nodeIdNumber, isSharedSpace, isSharedNode])
    const loadFigmaTaskComments = useCallback(async () => {
        if (isSharedSpace || isSharedNode || !nodeIdNumber) { return }
        await GetFigmaFileComments({
            nodeIds: [nodeIdNumber]
        }).then((res: FigmaFileCommentsAxiosResponseSuccessType) => {
            setFigmaTaskComments(res.data.comments)
        })
    }, [nodeIdNumber, isSharedSpace, isSharedNode]);



    const loadNodeData = useCallback(async () => {
        if (nodeIdNumber || (code && isSharedNode)) {
            await loadNode(nodeIdNumber);
        }
    }, [nodeIdNumber, code, isSharedNode, loadNode]);
    useEffect(() => {
        loadNodeData();
    }, [loadNodeData]);
    useEffect(() => {
        loadSlackMessages();
    }, [loadSlackMessages]);
    useEffect(() => {
        loadTeamsMessages();
    }, [loadTeamsMessages]);
    useEffect(() => {
        loadAtlassianTaskComments();
    }, [loadAtlassianTaskComments]);
    useEffect(() => {
        loadAsanaTaskComments();
    }, [loadAsanaTaskComments]);
    useEffect(() => {
        loadGitHubPullComments();
    }, [loadGitHubPullComments]);
    useEffect(() => {
        loadGitHubIssueComments();
    }, [loadGitHubIssueComments]);
    useEffect(() => {
        loadClickUpTaskComments();
    }, [loadClickUpTaskComments]);
    useEffect(() => {
        loadTrelloCardComments();
    }, [loadTrelloCardComments]);
    useEffect(() => {
        loadFigmaTaskComments();
    }, [loadFigmaTaskComments]);
    useEffect(() => {
        !!nodeIdNumber && loadNodeRelationsHandle(nodeIdNumber);
    }, [loadNodeRelationsHandle, nodeIdNumber]);
    const updateHandler = useCallback(async () => {
        if (nodeIdNumber || (code && isSharedNode)) {
            loadNodeData();
            loadSlackMessages();
            loadTeamsMessages();
            loadNodeRelationsHandle(nodeIdNumber);
            loadAtlassianTaskComments();
            loadAsanaTaskComments();
            loadGitHubPullComments();
            loadGitHubIssueComments();
            loadClickUpTaskComments();
            loadTrelloCardComments();
            loadFigmaTaskComments();
        }
    }, [nodeIdNumber, code, isSharedNode, loadNodeData, loadSlackMessages, loadTeamsMessages, loadNodeRelationsHandle, loadAtlassianTaskComments, loadAsanaTaskComments, loadGitHubPullComments, loadGitHubIssueComments, loadClickUpTaskComments, loadTrelloCardComments, loadFigmaTaskComments]);
    const onClose = () => {
        router.push({
            name: router.name,
            params: router.params,
            query: {
                ...router.query,
                node: undefined,
                space: undefined
            },
        });
        setTimeout(() => {
            setNode(undefined);
            setNodes([]);
            setSlackMessages([]);
            setTeamsMessages([]);
        }, 500);
    }
    const setActiveNodeIdHandle = (id: string | number | null) => {
        if (isSharedNode) { return }
        if (isSubscriptionExpired) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'payment',
                    plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                    node: undefined,
                }
            });
            return;
        }
        if (id === null) {
            onClose();
        } else {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    node: id,
                    space: spaceId
                },
            });
        }

    }
    const isFollowed = useMemo(() => {
        return !!followedNodes.find((followedNode) => followedNode.id === node?.id);
    }, [followedNodes, node]);
    const trackEvent = useAmplitude();
    useEffect(() => {
        if (router.name === 'publicNode') {
            trackEvent(EventTypes.SHARED_NODE_VISITED);
        }
    }, [router.name, trackEvent]);
    const contextValue: NodeContextType = {
        spaceId,
        nodes,
        node,
        isFollowed,
        relationships,
        slackMessages,
        teamsMessages,
        setActiveNodeId: setActiveNodeIdHandle,
        atlassianTaskComments,
        asanaTaskComments,
        githubPullComments,
        githubIssueComments,
        clickUpTaskComments,
        trelloCardComments,
        figmaTaskComments,
        isFetching,
        nodeUsers: users,
        update: () => {
            updateHandler();
            const updateSpaceData = new CustomEvent('updateDataByNodeDrawer');
            window.dispatchEvent(updateSpaceData);
        },
        isOpened: !!nodeQueryId && !nodeParamsId,
        onClose,
    };
    return <NodeContext.Provider value={contextValue}>
        {props.children}
    </NodeContext.Provider>;
};

export default NodeContext;
