import { FC } from "react";
import { MainLayout } from "@/components/v3/Layouts/MainLayout/MainLayout.component";
import { AddInSpaceProvider } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { Header } from "./components/Header/Header.component";
import { Sidebar } from "./components/Sidebar/Sidebar.component";
import { Tabs } from "./components/Tabs/Tabs.component";
import { Updates } from "./components/Updates/Updates.component";
import { Emails } from "./components/Emails/Emails.component";
import { Activities } from "./components/Activities/Activities.component";
import { FirstExperience } from "./components/FirstExperience/FirstExperience.component";
import { Meedings } from "./components/Meedings/Meedings.component";
import { Notes } from "./components/Notes/Notes.component";
import { CreateNoteDrawer } from "@/providers/v3/SpacePageCommon/components/CreateNoteDrawer/CreateNoteDrawer.component";

export const ForYouContent: FC = () => {
    const {
        update,
        activeTab
    } = useForYouPage();
    const shoudRenederMainSidebar = useRenederAbove('medium');
    return <AddInSpaceProvider onUpdate={update}>
        <MainLayout sidebar={shoudRenederMainSidebar ? <Sidebar /> : undefined}>
            <Header/>
            <Tabs />
            <FirstExperience />
            {activeTab==='UPDATES' && <Updates />}
            {activeTab==='EMAILS' && <Emails />}
            {activeTab==='ACTIVITIES' && <Activities />}
            {activeTab==='MEETINGS' && <Meedings />}
            {activeTab==='NOTES' && <Notes />}
        </MainLayout>
        <CreateNoteDrawer onUpdate={update} />
    </AddInSpaceProvider>
};