import { FC } from "react";
import { ActivityProps } from "./Activity.type";
import { Header } from "./components/Header/Header.component";
import { StyledActivitiesWrapper, StyledNode, StyledWrapper } from "./Activity.styled";
import { getSize } from "./Activity.utils";
import moment from "moment";
import { ActivityMessageOrComment } from "../ActivityMessageOrComment/ActivityMessageOrComment.component";
import { isItemExpired } from "@/utils/v3/IsItemExpired/IsItemExpired.util";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const Activity: FC<ActivityProps> = ({
    size: sizeProp,
    node,
    importantUserIds,
    activities,
    users,
    relationships,
    lastActor,
    className,
    onUpdate,
    useSkeleton = true
}) => {
    const { user } = useMe();
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    const isNodeExpired = isItemExpired(node.createdAt) && !node.sampleData && isIndividual;
    const size = getSize({ size: sizeProp, actionType: lastActor?.actionType })
    const activitiesWithComments = [...(activities || []), ...(node.comments || [])].sort((a, b) => {
        let aTime, bTime;
        if ('createdAt' in a) aTime = moment.utc(a.createdAt).local().toDate();
        else if ('messageTs' in a) aTime = moment.unix(parseInt(a.messageTs)).toDate();

        if ('createdAt' in b) bTime = moment.utc(b.createdAt).local().toDate();
        else if ('messageTs' in b) bTime = moment.unix(parseInt(b.messageTs)).toDate();
        if (!!aTime && !!bTime) {
            return bTime.getTime() - aTime.getTime();
        }
        return -1
    });;
    const activitiesWithCommentsJsx = activitiesWithComments.map((activityOrComment, i) => {
        if ('createdAt' in activityOrComment) {
            return <ActivityMessageOrComment useSkeleton={useSkeleton} isExpired={isNodeExpired} comment={activityOrComment} users={users} key={i} />
        } else {
            return <ActivityMessageOrComment useSkeleton={useSkeleton} isExpired={isNodeExpired} activityMessage={activityOrComment} users={users} key={i} />
        }
    });
    return <StyledWrapper className={className}>
        <Header useSkeleton={useSkeleton} node={node} size={size} actor={lastActor} isExpired={isNodeExpired}/>
        {size === 'large' && <StyledNode
            node={node}
            users={users}
            activitiesWithComments={activitiesWithComments}
            relationships={relationships}
            onUpdate={onUpdate}
            importantUserIds={importantUserIds}
            useSkeleton={useSkeleton}
        />}
        {size === 'small' && (!!activities?.length || !!node.comments?.length) && <StyledActivitiesWrapper>
            {activitiesWithCommentsJsx}
        </StyledActivitiesWrapper>}
    </StyledWrapper>
};