import { FC } from "react";
import { StyledFilledCard } from "./Tip.styled";
import { TipItem } from "./components/TipItem/TipItem.component";

export const Tip: FC = () => {
    return <StyledFilledCard>
        <TipItem>We don't store anything. No messages, docs, files, etc.</TipItem>
        <TipItem>Only public channels are accessible.</TipItem>
        <TipItem>Nobody can get what they don’t have access to.</TipItem>
    </StyledFilledCard>
};