import { styled } from "@mui/material";
import { NodeIcon } from "@/components/v3/Other/NodeIcon/NodeIcon.component";
import { RelationshipsIcon } from "@/icons/v3/relationships.icon";
import { SizeType } from "./Node.type";
import { DiscussionIcon } from "@/icons/v3/discussion.icon";
import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { People } from "./components/Actions/components/People/People.component";
import { TextSkeleton } from "../../Skeleton/TextSkeleton/TextSkeleton.component";
import { Button } from "../../Fields/Button/Button.component";

export const StyledNodeWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'size'
})<{
    size: SizeType;
}>`
    border-radius: 6px;
    border: ${({ size }) => size === 'small' ? 'none;' : '1px solid rgba(0, 0, 0, 0.08);'}
    background: #FFF;
    box-shadow: ${({ size }) => size === 'small' ? 'none;' : '0px 1px 1px 0px rgba(0, 0, 0, 0.12);'}
    width: 100%;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover {
        ${({ size }) => size === 'small' ? 'background: rgba(0, 0, 0, 0.06);' : 'border: 1px solid #202020;'}
    }
`;
export const StyledNodeContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'isNote'
})<{
    size: SizeType;
    isNote: boolean;
}>`
    padding: ${({ size }) => size === 'small' ? '12px 8px;' : '16px;'}
    display: flex;
    justify-content: space-between;
    align-items: ${({ isNote }) => isNote ? 'flex-start;' : 'center;'}
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        align-items: flex-start;
    }
`;
export const StyledNodeContent = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 auto;
    max-width: calc(100% - 72px);
`;
export const StyledNodeIcon = styled(NodeIcon)`
    width: 32px;
    height: 32px;
    margin-top: 4px;
`;
export const StyledTextContent = styled('div')`
    padding-left: 8px;
    width: calc(100% - 32px);
`;
export const StyledNodeContentName = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 48px;
`;
export const StyledNodeContentNote = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    & * {
      margin: 0;
      color: #202020;
      font-family: Poppins;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }

    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 48px;
`;
export const StyledNodeContentCaption = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;
`;
export const StyledNodeApplication = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // word-break: break-word;
    max-width: calc(100% - 81px);
`;
export const StyledRelationshipsIcon = styled(RelationshipsIcon)`
    width: 16px;
    height: 16px;
    margin-right: 4px;
    & path {
        fill: #B5B5B5;
    }
`;
export const StyledDiscussionIcon = styled(DiscussionIcon)`
    width: 16px;
    height: 16px;
    margin-right: 4px;
    & path {
        fill: #B5B5B5;
    }
`;
export const StyledNumber = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
`;

export const StyledNodeActivities = styled('div')`
    border-top: 1px solid rgba(0, 0, 0, 0.08);
`;
export const StyledPeople = styled(People)`
    margin-top: 4px;
    margin-left: 8px;
`;
export const StyledShowAll = styled(Button)`
    margin-left: 16px;
    margin-bottom: 8px;
    margin-top: -8px;
`;
export const StyledExpiredLabel = styled('div')`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;