import { Box, styled } from "@mui/material";

export const StyledWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const StyledContainer = styled(Box)`
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledText = styled('span')`
    padding-left: 0;
`;
export const StyledLoader = styled(Box)`
    padding-left: 100px;
`;