import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { styled } from "@mui/material";
import { LoaderLayout } from "../LoaderLayout/LoaderLayout.component";

export const StyledWrapper = styled("div")`
    min-width: 560px;
    background-color: #fff;
    position: relative;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 64px);
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
        height: 30px;
    }
    &::-webkit-scrollbar-track {
        background: rgb(0,0,0,0.08);
    }
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        min-width: 0;
        max-height: 100vh;
    }
`;
export const StyledHeader = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding: 16px;
    }
    color: #202020;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;
export const StyledContent = styled("div", {
    shouldForwardProp: (prop) => prop !== "useContetPaddings"
})<{useContetPaddings: boolean}>`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: ${({useContetPaddings}) => useContetPaddings ? "24px" : "0"};
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding: ${({useContetPaddings}) => useContetPaddings ? "16px" : "0"};
        padding-bottom: 80px;
    }
`;
export const StyledActions = styled("div")`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 24px;
    padding-left: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 2;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        position: fixed;
        width: 100%;
    }
`;
export const StyledLoaderLayout = styled(LoaderLayout)`
    height: 100%;
`;