export const STAGE_PARAM_NAME = 'stage';
export const STAGE_PARAM_AFTER_CONNECT_VALUE = 'afterConnect';
export const STAGE_PARAM_ERROR_VALUE = 'error';

export const initialIntegrationStatus = {
  isSlackActive: false,
  isAsanaActive: false,
  isGitHubActive: false,
  isClickUpActive: false,
  isTrelloActive: false,
  isFigmaActive: false,
  isGoogleDriveActive: false,
  isGmailActive: false,
  isAtlassianActive: false,
  isDropboxActive: false,
  isTeamsActive: false,
  isAzureMailActive: false,
  isNotionActive: false,
  isGoogleCalendarActive: false,
  isAtLeastOneServiceConnected: false,
}