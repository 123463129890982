import { FC } from "react";
import { MainLayoutProps } from "./MainLayout.type";
import { StyledMainContent, StyledMainContentWrapper, StyledMainHeader, StyledMainLayout, StyledMainSidebar, StyledMainWrapper, StyledRightSidebar } from "./MainLayout.styled";
import { Sidebar } from "./components/Sidebar/Sidebar.component";
import { SpacesProvider } from "@/providers/SpacesProvider/Spaces.provider";
import { Header } from "./components/Header/Header.component";
import { useRenederAbove } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { ChannelsProvider } from "@/providers/v3/Channels/Channels.provider";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { CreateSpaceModal } from "@/components/v3/Modals/CreateSpaceModal/CreateSpaceModal.component";
import { ManageIntegrationsDialog } from "@/components/v3/Modals/ManageIntegrationsDialog/ManageIntegrationsDialog.component";
import { UpgradeToTeamPlanModal } from "../../Modals/UpgradeToTeamPlanModal/UpgradeToTeamPlanModal.component";
import { ManageUsersDialog } from "../../Modals/ManageUsersDialog/ManageUsersDialog.component";
import { TrialOverBanner } from "./components/TrialOverBanner/TrialOverBanner.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { TrackRecentVisit } from "@/components/TrackRecentVisit";

export const MainLayout: FC<MainLayoutProps> = ({ children, sidebar }) => {
    const useSidebar = !!sidebar;
    const { isShared } = useIsShared();
    const isDesktop = useRenederAbove('small');
    const shoudRenederMainSidebar = isDesktop;
    const { user } = useMe();
    const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED'
    return <ChannelsProvider>
        <SpacesProvider>
            {isSubscriptionExpired && <TrialOverBanner />}
            <TrackRecentVisit />
            <StyledMainLayout>
                {shoudRenederMainSidebar && <StyledMainSidebar>
                    <Sidebar />
                </StyledMainSidebar>}
                <StyledMainWrapper withSidebar={shoudRenederMainSidebar} >
                    {!isShared && <StyledMainHeader>
                        <Header />
                    </StyledMainHeader>}
                    <StyledMainContentWrapper>
                        <StyledMainContent useSidebar={useSidebar} id="main-content">
                            {children}
                        </StyledMainContent>
                        {useSidebar && <StyledRightSidebar>{sidebar}</StyledRightSidebar>}
                    </StyledMainContentWrapper>
                </StyledMainWrapper>
            </StyledMainLayout>
            <CreateSpaceModal />
            <ManageIntegrationsDialog />
            <UpgradeToTeamPlanModal />
            <ManageUsersDialog />
        </SpacesProvider>
    </ChannelsProvider>
};