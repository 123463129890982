import { Route, Switch } from 'react-router-dom';
import { RequestResetPassword } from '../../pages/v3/RequestResetPasswordPage/RequestResetPassword.page';
import { ChangePassword } from '../../pages/v3/ChangePassword/ChangePassword.page';
import { mainLayoutRoutesConfig, onboardingLayoutRouteConfig } from '../RoutesConfig';
import { MainContentLayout } from '../MainContentLayout/MainContent.layout';
import { OnboardingRoutes } from '../OnboardingRoutes';
import { Box } from '@mui/material';
import { TermsOfService } from '../../components/TermsOfService';
import { Privacy } from '../../components/Privacy';
import { OnboardingConfirmationLinkPage } from "../../pages/OnboardingConfirmationLink/OnboardingConfirmationLink.page";
import { EmailConfirmedPage } from "../../pages/v3/EmailConfirmed/EmailConfirmed.page";
import { LoadingPage } from "../../pages/LoadingPage/Loading.page";
import { SignupFromInvite } from "../../pages/v3/SignupFromInvite/SignupFromInvite.page";
import { TwoFA } from 'pages/TwoFA/TwoFA.page';
import { NotFound } from 'pages/NotFound/NotFound.page';
import { SpacesProvider } from 'providers/SpacesProvider/Spaces.provider';
import { StyledContent } from './LayoutResolver.styled';
import { SpacePage } from 'pages/v3/Space/Space.page';
import { SpaceCategoryPage } from 'pages/v3/SpaceCategory/SpaceCategory.page';
import { SpaceTagPage } from 'pages/v3/SpaceTag/SpaceTag.page';
import { LoginPage } from 'pages/v3/Login/Login.page';
import { SignupPage } from 'pages/v3/Signup/Signup.page';
import { NodeNeighboursPage } from 'pages/v3/NodeNeighbours/NodeNeighbours.page';
import { ForYouPage } from 'pages/v3/ForYou/ForYou.page';
import { ImportantPage } from 'pages/v3/Important/Important.page';
import { PeoplePage } from 'pages/v3/People/People.page';
import { CommonRelationsPage } from 'pages/v3/CommonRelations/CommonRelations.page';

export const LayoutResolver = () => {
  return (
    <>
      <Box>
        <StyledContent>
          <Switch>
            <Route path="/for-you">
              <ForYouPage />
            </Route>
            <Route path="/common-relations/:fromUserId">
              <CommonRelationsPage />
            </Route>
            <Route path="/important">
              <ImportantPage />
            </Route>
            <Route path="/people">
              <PeoplePage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>
            <Route path="/node-neighbours/:nodeId">
              <NodeNeighboursPage />
            </Route>
            <Route exact path="/public/space/:code">
              <SpacePage />
            </Route>
            <Route exact path="/public/space/:code/:category">
              <SpaceCategoryPage />
            </Route>
            <Route exact path="/public/space/:code/tags/:tagId">
              <SpaceTagPage />
            </Route>
            <Route path="/two-factor-authentication/:secret">
              <TwoFA />
            </Route>
            <Route path="/reset-password">
              <RequestResetPassword />
            </Route>
            <Route path="/change-password">
              <ChangePassword />
            </Route>
            <Route path="/invite/signup">
              <SignupFromInvite />
            </Route>
            <Route path="/link-confirmation">
              <OnboardingConfirmationLinkPage />
            </Route>
            <Route path="/confirmation/:confirmationCode">
              <EmailConfirmedPage />
            </Route>
            <Route path="/loading-page">
              <LoadingPage />
            </Route>
            <Route path="/terms">
              <TermsOfService />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/404">
              <NotFound />
            </Route>
            <Route exact path="/space/:spaceId">
              <SpacePage />
            </Route>
            <Route exact path="/space/:spaceId/:category">
              <SpaceCategoryPage />
            </Route>
            <Route exact path="/space/:spaceId/tags/:tagId">
              <SpaceTagPage />
            </Route>
            <Route exact path={onboardingLayoutRouteConfig.map(route => route.path)}>
              <OnboardingRoutes />
            </Route>
            <Route exact path={mainLayoutRoutesConfig.map(route => route.path)}>
              <SpacesProvider>
                <MainContentLayout />
              </SpacesProvider>
            </Route>
          </Switch>
        </StyledContent>
      </Box>
    </>
  );
};
