export const routes = {
    login: {
        path: '/login',
        type: ['public'],
    },
    signup: {
        path: '/signup',
        type: ['public'],
    },
    resetPassword: {
        path: '/reset-password',
        type: ['public'],
    },
    resetPasswordv2: {
        path: '/v2/reset-password',
        type: ['public'],
    },
    twoFactorAuthntication: {
        path: '/two-factor-authentication/[secret]',
        type: ['public'],
    },
    changePassword: {
        path: '/change-password',
        type: ['public'],
    },
    inviteSignup: {
        path: '/invite/signup',
        type: ['public'],
    },
    linkConfirmation: {
        path: '/link-confirmation',
        type: ['public'],
    },
    confirmation: {
        path: '/confirmation/[confirmationCode]',
        type: ['public', 'private', 'onboarding'],
    },
    terms: {
        path: '/terms',
        type: ['public'],
    },
    privacy: {
        path: '/privacy',
        type: ['public'],
    },
    promo: {
        path: '/promo',
        type: ['public'],
    },
    loadingPage: {
        path: '/loading-page',
        type: ['onboarding'],
    },
    manageIntegrations: {
        path: '/manage-integrations',
        type: ['onboarding'],
    },
    chromeExtension: {
        path: '/chrome-extension',
        type: ['onboarding'],
    },
    invite: {
        path: '/invite',
        type: ['onboarding'],
    },
    connectSlackChannels: {
        path: '/connect-slack-channels',
        type: ['onboarding'],
    },
    connectClickUpProjects: {
        path: '/connect-click-up-projects',
        type: ['onboarding'],
    },
    connectTrelloBoards: {
        path: '/connect-trello-boards',
        type: ['onboarding'],
    },
    connectAsanaProjects: {
        path: '/connect-asana-projects',
        type: ['onboarding'],
    },
    
    connectGitHubProjects: {
        path: '/connect-github-projects',
        type: ['onboarding'],
    },
    connectAtlassianProjects: {
        path: '/connect-atlassian-projects',
        type: ['onboarding'],
    },
    connectMicrosoftTeamsProjects: {
        path: '/connect-microsoft-teams-projects',
        type: ['onboarding'],
    },
    selectPlan: {
        path: '/select-plan',
        type: ['onboarding'],
    },
    forYou: {
        path: '/for-you',
        type: ['private'],
    },
    important: {
        path: '/important',
        type: ['private'],
    },
    documents: {
        path: '/documents',
        type: ['private'],
    },
    people: {
        path: '/people',
        type: ['private'],
    },
    nodeNeighbours: {
        path: '/node-neighbours/[nodeId]',
        type: ['private'],
    },
    spaceNodeNeighbours: {
        path: '/space/[spaceId]/node-neighbours/[nodeId]',
        type: ['private'],
    },
    commonRelations: {
        path: '/common-relations/[fromUserId]',
        type: ['private'],
    },
    space: {
        path: '/space/[spaceId]',
        type: ['private'],
    },
    spacev2: {
        path: '/v2/space/[spaceId]',
        type: ['private'],
    },
    spaceCategory: {
        path: '/space/[spaceId]/[category]',
        type: ['private'],
    },
    spaceCategoryv2: {
        path: '/v2/space/[spaceId]/[category]',
        type: ['private'],
    },
    spaceTag: {
        path: '/space/[spaceId]/tags/[tagId]',
        type: ['private'],
    },
    spaceTagv2: {
        path: '/v2/space/[spaceId]/tags/[tagId]',
        type: ['private'],
    },
    publicNode: {
        path: '/public/node/[code]',
        type: ['private', 'public'],
    },
    publicSpace: {
        path: '/public/space/[code]',
        type: ['private', 'public'],
    },
    publicSpaceCategory: {
        path: '/public/space/[code]/[category]',
        type: ['private', 'public'],
    },
    publicSpaceTag: {
        path: '/public/space/[code]/tags/[tagId]',
        type: ['private', 'public'],
    },
    notFound: {
        path: '/404',
        type: ['public'],
    },
}