import { FC, useMemo, useState } from "react";
import { StyledWrapper } from "./FirstExperience.styled";
import { FirstExperienceLayout } from "@/components/v3/Layouts/FirstExperienceLayout/FirstExperienceLayout.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { UpdateFetch } from "@/axios/OrganizationService/Users/Users.api";
import { PatchBodyParams } from "@/axios/OrganizationService/Users/Types/Patch/Patch.type";

export const FirstExperience: FC = () => {
    const [isFething, setIsFething] = useState<boolean>(false);
    const { user, loadUser } = useMe();
    const router = useRouter();
    const steps = 4;
    const progress = 1 / steps;
    const step = useMemo(() => {
        if (!user?.createdSpace && !user?.skipCreatedSpace) return 1;
        if (!user?.invitedTeamMate && !user?.skipInvitedTeamMate) return 2;
        if (!user?.installedChromeExtension && !user?.skipInstalledChromeExtension) return 3;
        if (!user?.connectedApp && !user?.skipConnectedApp) return 4;
        return 5;
    }, [user?.connectedApp, user?.createdSpace, user?.installedChromeExtension, user?.invitedTeamMate, user?.skipConnectedApp, user?.skipCreatedSpace, user?.skipInstalledChromeExtension, user?.skipInvitedTeamMate]);
    const skipHandler = async (body: PatchBodyParams) => {
        if (!user?.id) return;
        setIsFething(true);
        await UpdateFetch(user.id, body);
        await loadUser();
        setIsFething(false);
    };
    if (step===5) return null;
    return <StyledWrapper>
        <LoaderLayout isLoading={isFething} transparent>
            {step===1 && <FirstExperienceLayout
                title="Create first space"
                description="Spaces will contain all resources and updates for your projects and topics"
                buttonText="Create space"
                progress={progress}
                onClick={() => {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            dialog: 'create-space'
                        }
                    });
                }}
            />}
            {step===2 && <FirstExperienceLayout
                title="Invite people"
                description="Add your teammates or customers"
                buttonText="Invite people"
                progress={progress*2}
                onClick={() => {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                        ...router.query,
                        dialog: 'manage-users',
                        }
                    })
                }}
                onSkip={() => skipHandler({ skipInvitedTeamMate: true })}
            />}
            {step===3 && <FirstExperienceLayout
                title="Install Chrome extension"
                description="Add tabs to Sense and search across all apps right from your browser"
                buttonText="Install"
                progress={progress*3}
                onClick={() => {
                    window.open('https://chrome.google.com/webstore/detail/sense/obemfgmikdfkejnfioiodicdhjnnojla', '_blank');
                }}
                onSkip={() => skipHandler({ skipInstalledChromeExtension: true })}
            />}
            {step===4 && <FirstExperienceLayout
                title="Connect apps"
                description="Sense will automatically organize all data from your apps"
                buttonText="Connect apps"
                progress={progress*4}
                onClick={() => router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: 'manage-integrations'
                    }
                })}
                onSkip={() => skipHandler({ skipConnectedApp: true })}
            />}
        </LoaderLayout>
    </StyledWrapper>
};