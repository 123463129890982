import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { CategoryGroup } from "@/components/v3/List/CategoryGroup/CategoryGroup.component";
import { EmptyCard } from "@/components/v3/Other/EmptyCard/EmptyCard.component";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { StyledFiltersWrapper, StyledSelect, StyledWrapper } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { useAddInSpace } from "@/providers/v3/AddInSpace/AddInSpace.provider";
import { useSpaceTagPage } from "@/providers/v3/SpaceTagPage/SpaceTagPage.provider";
import { getAppOptionsFromNodeWrappers } from "@/utils/v3/GetAppOptionsFromNodes/GetAppOptionsFromNodes.util";
import { getPeopleOptionsFromNodes } from "@/utils/v3/GetPeopleOptionsFromNodes/GetPeopleOptionsFromNodes.util";
import { groupNodeWrappersByCategory } from "@/utils/v3/GroupNodeByCategory/GroupNodeByCategory.util";
import { FC, useMemo, useState } from "react";
import { EmptyState } from "./components/EmptyState/EmptyState.component";

export const Content: FC = () => {
    const { listOfNodes, update, listOfSpaceTagUsers, isLoading } = useSpaceTagPage();
    const { addInSpace } = useAddInSpace();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedApps, setSelectedApps] = useState<string[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const appsOptions = useMemo((): OptionType<string>[] => {
        return getAppOptionsFromNodeWrappers(listOfNodes);
    }, [listOfNodes]);

    const peopleOptions = useMemo((): OptionType<string>[] => {
        return getPeopleOptionsFromNodes(listOfNodes);
    }, [listOfNodes]);
    const filteredListOfNodes = useMemo(() => {
        return listOfNodes.filter((wrapper) => {
            if (selectedApps.length && !selectedApps.includes(wrapper.node.application)) {
                return false;
            }
            if (selectedPeople.length) {
                const actorIds = wrapper.relationships?.actors?.map(actor => actor.userId) || [];
                if (!actorIds.some(id => selectedPeople.includes(id))) {
                    return false;
                }
            }
            return true;
        });
    }, [listOfNodes, selectedApps, selectedPeople]);
    const allGroups = useMemo(() => {
        return groupNodeWrappersByCategory(listOfNodes);
    }, [listOfNodes]);
    const groups = useMemo(() => {
        return groupNodeWrappersByCategory(filteredListOfNodes);
    }, [filteredListOfNodes]);
    const filteredGroups = useMemo(() => {
        if (!selectedCategories.length) {
            return groups;
        }
        return groups.filter((group) => {
            return selectedCategories.includes(group.category);
        });
    }, [groups, selectedCategories]);
    const listOfCategoriesJsx = filteredGroups.map((group, i) => {
        return <CategoryGroup useShowAll title={group.category} nodes={group.wrappers.map((wrapper) => {
            return {
                node: wrapper.node,
                users: listOfSpaceTagUsers,
                relationships: wrapper.relationships,
                onUpdate: update,
                importantUserIds: wrapper.importantUserIds
            }
        })} key={i} />
    });

    return <StyledWrapper>
        <LoaderLayout isLoading={isLoading} transparent={!!listOfNodes.length}>
            <EmptyStateLayoyt
                value={!listOfNodes.length && !isLoading}
                emptyNode={<EmptyState />}
            >
                <StyledFiltersWrapper>
                    <StyledSelect
                        label="Categories"
                        value={selectedCategories}
                        onChange={(val: any) => setSelectedCategories(val)}
                        options={allGroups.map((group) => {
                            return { label: group.category, value: group.category}
                        })}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                    />
                    <StyledSelect
                        label="Apps"
                        value={selectedApps}
                        onChange={(val: any) => setSelectedApps(val)}
                        options={appsOptions}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                    />
                    <StyledSelect
                        label="People"
                        value={selectedPeople}
                        onChange={(val: any) => setSelectedPeople(val)}
                        options={peopleOptions}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        icon={<FilterIcon />}
                    />
                </StyledFiltersWrapper>
                {listOfCategoriesJsx}
            </EmptyStateLayoyt>
        </LoaderLayout>
    </StyledWrapper>
};