import { FC, useMemo, useState } from "react";
import { StyledAdd2Icon, StyledDefaultEmojiIcon, StyledEmojiContainer, StyledEmojiIcon, StyledEmojiSelect, StyledEmojiWrapper, StyledMainIcon, StyledMaskedImg, StyledPageHeader } from "./Header.styled";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { getIconPath } from "@/components/v3/Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.utils";
import { HeaderProps } from "./Header.type";
import { ShareButton } from "./components/ShareButton/ShareButton.component";
import { Menu } from "./components/Menu/Menu.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { AddButton } from "./components/AddButton/AddButton.component";

export const Header: FC<HeaderProps> = ({
  space,
  onNewEmoji,
  showAddButton = false,
  disabledShare = false
}) => {
    const { user } = useMe();
    const isSubscriptionExpired = user?.subscriptionStatus === 'EXPIRED';
    const [isOpenedSelect, setIsOpenedSelect] = useState<boolean>(false);
    const [isHover, setIsHover] = useState<boolean>(false);
    const { isSharedSpace } = useIsShared();
    const isActive = isHover || isOpenedSelect;
    const icon = useMemo((): string => {
      const isPrivate = space && space.private;
      if (!space) {
        return '/icons/private-space-active-icon.svg'
      }
      if (isPrivate) {
        return '/icons/private-space-active-icon.svg';
      }
      return getIconPath(space.application, true);
  
    }, [space]);
    const iconJsx = useMemo(() => {
      if (isSharedSpace || isSubscriptionExpired) {
        return <StyledEmojiContainer>
          {!isSubscriptionExpired && <>
            {!!space?.emoji && <StyledEmojiIcon >{space.emoji}</StyledEmojiIcon>}
            {!space?.emoji && <StyledMainIcon src={icon}/>}
          </>}
          {isSubscriptionExpired && <StyledMaskedImg />}
        </StyledEmojiContainer>
      }
      return <StyledEmojiSelect isEmoji={!!space.emoji} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onChange={(e) => onNewEmoji(e.native)} onChangeIsOpened={setIsOpenedSelect}>
        {!!space?.emoji && <StyledEmojiIcon >{space.emoji}</StyledEmojiIcon>}
        {!space?.emoji && <StyledMainIcon src={icon}/>}
        <StyledEmojiWrapper isActive={isActive} >
          <StyledDefaultEmojiIcon isActive={isActive} />
          <StyledAdd2Icon isActive={isActive} />
        </StyledEmojiWrapper>
      </StyledEmojiSelect>
    }, [space?.emoji, icon, isActive, isSharedSpace, onNewEmoji, isSubscriptionExpired]);
    
    return <StyledPageHeader title={space?.name || ''} subtitle="Sense Space" icon={iconJsx} >
      {!isSharedSpace && <>
        <Menu space={space} />
        {!!showAddButton && <>
          <ShareButton disabled={disabledShare} />
          <AddButton />
        </>}
      </>}
    </StyledPageHeader>
};