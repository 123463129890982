import { FC, useMemo, useState } from "react";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { StyledAddIcon, StyledEmptyCardWrapper, StyledSectionHeader } from "../../Sidebar.styled";
import { useShowMore } from "@/hooks/v3/UseShowMore/UseShowMore.hook";
import { useRouter } from "@/providers/Router/Router.provider";
import { StyledList, StyledPerson } from "./People.styled";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { EmptyCard } from "@/components/v3/Other/EmptyCard/EmptyCard.component";
import { EditPeopleDialog } from "./components/EditPeopleDialog/EditPeopleDialog.component";
import { useSpacePageCommon } from "@/providers/v3/SpacePageCommon/SpacePageCommon.provider";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";

export const People: FC = () => {
    const {
        isFetchingListOfSpaceUsers,
        space,
    } = useSpacePageCommon();
    const [isOpenedEditPeopleDialogModal, setIsOpenedEditPeopleDialogModal] = useState<boolean>(false);
    const router = useRouter();
    const { isShared } = useIsShared();
    
    const { users, user } = useMe();
    const filteredListOfUsers = useMemo(() => {
        return users.filter((el) => el.id !== user?.id)
    }, [users, user]);
    const listOfUserIds = space?.userIds.filter((el) => el!== user?.id ) || [];
    const listOfPeople = !!users.length ? listOfUserIds.map((userId) => {
        return users.find((user) => user.id === userId)!;
    }) : [];
    
    const { items, showMore, setShowMore } = useShowMore({ items: listOfPeople, limit: 5 });
    const listOfPeopleJsx = items.map((user, i) => {
        return <StyledPerson
            key={i}
            person={user}
            onClick={() => router.push({name: 'commonRelations', params: {fromUserId: user.id}})}
        />
    });
    const isEmpty = !listOfPeople.length && !isFetchingListOfSpaceUsers;
    const openDialogHandler = () => {
        if (!!filteredListOfUsers.length) {
            setIsOpenedEditPeopleDialogModal(true)
        } else {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: [router.query.dialog as string, 'manage-users'],
                }
            });
        }
    }
    return <>
        {(!isEmpty || (isEmpty && !isShared)) && <>
            <StyledSectionHeader title="People">
                {!!listOfPeople.length && !isShared && <Button 
                    onClick={openDialogHandler}
                    icon={!filteredListOfUsers.length && <StyledAddIcon />}
                    variant="tertiary"
                >{!!filteredListOfUsers.length ? 'Manage' : 'Add'}</Button>}
            </StyledSectionHeader>
            <LoaderLayout isLoading={isFetchingListOfSpaceUsers} transparent={!!listOfPeople.length}>
                <EmptyStateLayoyt
                    value={!listOfPeople.length && !isFetchingListOfSpaceUsers}
                    emptyNode={<StyledEmptyCardWrapper>
                        <EmptyCard title="Add people to space" onClick={openDialogHandler}>
                            Let your colleagues get their work resources and updates at one place
                        </EmptyCard>
                    </StyledEmptyCardWrapper>}
                >
                    <StyledList>
                        {listOfPeopleJsx}
                        {!showMore && listOfPeople.length > items.length && <Button variant='tertiary' onClick={() => setShowMore(true)}>Show all</Button>}
                    </StyledList>
                </EmptyStateLayoyt>
                <EditPeopleDialog value={isOpenedEditPeopleDialogModal} onChange={setIsOpenedEditPeopleDialogModal} />
            </LoaderLayout>
        </>}
    </>
};