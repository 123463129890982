import { FC } from "react";
import { TabValueType, TabsProps } from "./Tabs.type";
import { StyledTab, StyledTabs, StyledWrapper } from "./Tabs.styled";

export const Tabs: FC<TabsProps> = ({
    value,
    onChange
}) => {
    return <StyledWrapper>
        <StyledTabs value={value} onChange={(newVal) => onChange(newVal as TabValueType)}>
            <StyledTab value="COMMON_RELATIONSHIPS">Common relationships</StyledTab>
            <StyledTab value="RECENT_ACTIVITIES">Recent activities</StyledTab>
            <StyledTab value="ALL_RELATIONSHIPS">All relationships</StyledTab>
        </StyledTabs>
    </StyledWrapper>
};