import {
    CircularProgress,
} from "@mui/material";
import { useState, FC } from "react";
import { useLocation } from "react-router-dom";
import { useMe } from "providers/MeProvider/Me.provider";
import { StartIntegrationDialogProps } from "./StartIntegrationDialog.type";
import { StyledCancelButton } from "./StartIntegrationDialog.styled";
import { formatName } from "./StartIntegrationDialog.utils";
import { HttpClient } from "@/axios/HttpClient";
import { useModal } from "@/store/ModalContext";
import { envUrl } from "@/service/helpers";
import { ToastService } from "@/service/ToastService";
import { UpdateFetch } from "@/axios/OrganizationService/Users/Users.api";
import { useRouter } from "@/providers/Router/Router.provider";
import { useChannels } from "@/providers/v3/Channels/Channels.provider";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";

export const StartIntegrationDialog: FC<StartIntegrationDialogProps> = ({ userId, open }: StartIntegrationDialogProps) => {
    const { setShouldStartIntegration, user, loadUser } = useMe();
    const router = useRouter();
    const { closeStartIntegrationModal } = useModal();
    const { update } = useChannels();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    const [isLoading, setIsLoading] = useState(false);
    const connectedHandler = async () => {
        if (!user?.id) return;
        await UpdateFetch(user.id, {
            connectedApp: true,
        });
        await loadUser();
    };
    const isMobile = useRenederBelow('tablet');
    const handleSyncronization = () => {
        setIsLoading(true);
        HttpClient.get(`${envUrl}/sense-facade/v1/integrations/synchronization?userId=${userId}&type=${type}`)
            .then(response => {
                HttpClient.get(`${envUrl}/sense-facade/v1/users/${userId}/info`)
                    .then(response => response.data)
                    .then(data => {
                        type && data.integrations[type].active && setIsLoading(false)
                    })
                    .catch(error => {
                        ToastService.showToast('error', 'Some error occurred!');
                    });
            })
            .catch(error => {
                ToastService.showToast('error', 'Some error occurred! Please try again later!')
            })
            .finally(async () => {
                closeStartIntegrationModal();
                await update();
                if (!!router.query.spaceApp) {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            spaceApp: router.query.spaceApp,
                            dialog: router.name === 'space' ? 'edit-space' : 'create-space',
                        }
                    })
                }
                if (!!router.query.addTab) {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            tab: isMobile ? '' : router.query.addTab,
                            dialog: 'add-to-space',
                        }
                    })
                }
                setShouldStartIntegration(true);
                if (!user?.connectedApp) {
                    connectedHandler();
                }
            })
    }
    return (
        <>
            <Dialog
                value={open}
                onChange={closeStartIntegrationModal}
                size="small"
            >
                <DialogLayout title={<>{type && `${formatName(type)} integration`}</>} actions={<>
                    <StyledCancelButton size="large" variant="error" onClick={closeStartIntegrationModal}>
                        Cancel
                    </StyledCancelButton>
                    <Button size="large" onClick={handleSyncronization}>
                        {isLoading ? <CircularProgress style={{ color: "white", marginTop: 5 }} size={20} /> : 'Start integration'}
                    </Button>
                </>}>
                    Sense is ready to automatically organise resources from this app. It doesn’t write anything in your apps and don’t store any date from this app. No one will access the resources they are not permitted to access.
                </DialogLayout>
            </Dialog>
        </>
    )
}