import { FC } from "react";
import { GroupLayoutProps } from "./GroupLayout.type";
import { StyledFilledCard, StyledTitle, StyledWrapper } from "./GroupLayout.styled";

export const GroupLayout: FC<GroupLayoutProps> = ({
    title, children,
}) => {
    return <StyledWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledFilledCard>
            {children}
        </StyledFilledCard>
    </StyledWrapper>
};