import { FC, createContext, useContext, useState, useEffect, useMemo, useCallback } from "react";
import { ForYouPageProviderProps, ForYouPageContextType, ActivityTypeWithUser, RecentActivityTypeWithRelationships, TabValueType, ImportantWithRelationships } from "./ForYouPage.type";
import { useMe } from "providers/MeProvider/Me.provider";
import { CalendarEventsAxiosResponseSuccessType, GoogleCalendarEventType } from "@/axios/SenseFacade/CalendarEvents/Types/Get/Get.type";
import { ActivitiesDetailsAxiosResponseSuccessType } from "@/axios/SenseFacade/ActivitiesDetails/Types/Get/Get.type";
import { NodeRecentActivitiesAxiosResponseSuccessType } from "@/axios/AiService/NodeRecentActivities/Types/Get/Get.type";
import { ToastService } from "service/ToastService";
import { GetCalendarEventsFetch } from "@/axios/SenseFacade/CalendarEvents/CalendarEvents.api";
import { GetActivitiesDetailsFetch } from "@/axios/SenseFacade/ActivitiesDetails/ActivitiesDetails.api";
import { GetNodeRecentActivitiesFetch } from "@/axios/AiService/NodeRecentActivities/NodeRecentActivities.api";
import { fetchNodeRelations } from "utils/FetchNodeRelations/FetchNodeRelations.util";
import { GetMentionsFetch } from "@/axios/SenseFacade/Mentions/Mentions.api";
import { MentionType, MentionsAxiosResponseSuccessType } from "@/axios/SenseFacade/Mentions/Types/Get/Get.type";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";
import { findLastActor } from "@/components/v3/List/Activity/Activity.utils";
import { NodeTypeType } from "@/types/Node/Node.type";
import { GetNodesApplicationsFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { NodesApplicationsAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/Applications/Applications.type";
import { GetNodesFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { NodesAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/Get/Get.type";

const defaultConfig: ForYouPageContextType = {
    update: () => undefined,
    listOfMentions: [],
    dailyCalendarEvents: [],
    notDailyCalendarEvents: [],
    listOfActivitiesDetailsWithUser: [],
    listOfEmailResentActivities: [],
    listOfNoteResentActivities: [],
    listOfImportant: [],

    isLoading: true,
    isLoadingGoogleCalendarEvents: true,

    activeTab: 'UPDATES',
    setActiveTab: () => undefined,

    selectedDate: new Date(),
    setSelectedDate: () => undefined,
    selectedUsers: [],
    setSelectedUsers: () => undefined,

    isFetchingRecentActivities: false,
    isFetchingEmailRecentActivities: false,
    isFetchingNoteRecentActivities: false,
    isFetchingImportant: false,
};

export const ForYouPageContext = createContext<ForYouPageContextType>(defaultConfig);
export const useForYouPage = () => useContext<ForYouPageContextType>(ForYouPageContext);

export const ForYouPageProvider: FC<ForYouPageProviderProps> = (
    { children }: ForYouPageProviderProps
) => {
    const { users, user, followedNodes } = useMe();
    const [isFetchingRecentActivities, setIsFetchingRecentActivities] = useState<boolean>(false);
    const [isFetchingEmailRecentActivities, setIsFetchingEmailRecentActivities] = useState<boolean>(false);
    const [isFetchingNoteRecentActivities, setIsFetchingNoteRecentActivities] = useState<boolean>(false);
    const [isFetchingImportant, setIsFetchingImportant] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<TabValueType>('UPDATES');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingGoogleCalendarEvents, setIsLoadingGoogleCalendarEvents] = useState<boolean>(true);
    const [listOfMentions, setListOfMentions] = useState<MentionType[]>([]);
    const [listOfGoogleCalendarEvents, setListOfGoogleCalendarEvents] = useState<GoogleCalendarEventType[]>([]);
    const [listOfEmailResentActivities, setListOfEmailResentActivities] = useState<RecentActivityTypeWithRelationships[]>([]);
    const [listOfNoteResentActivities, setListOfNoteResentActivities] = useState<ActivityTypeWithUser[]>([]);
    const [listOfActivitiesDetailsWithUser, setListOfActivitiesDetailsWithUser] = useState<ActivityTypeWithUser[]>([]);
    const [listOfImportantWithRelationships, setListOfImportantWithRelationships] = useState<ImportantWithRelationships[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

    const dailyCalendarEvents = useMemo(() => {
        return listOfGoogleCalendarEvents.filter(calendarEvent => calendarEvent.daily)
    }, [listOfGoogleCalendarEvents]);
    const notDailyCalendarEvents = useMemo(() => {
        return listOfGoogleCalendarEvents.filter(calendarEvent => !calendarEvent.daily)
    }, [listOfGoogleCalendarEvents]);

    const loadListOfMentions = useCallback(async () => {
        if (!user) {
            return
        }
        await GetMentionsFetch({
            userId: user.id,
            users: selectedUsers,
        }).then(async (resMentions: MentionsAxiosResponseSuccessType) => {
            setListOfMentions(resMentions.data.mentions);
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
        });
    }, [selectedUsers, user]);
    const { start: startTimeMilliseconds, end: endTimeMilliseconds } = useMemo(() => {
        const startOfDay = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 0, 0, 0));
        const endOfDay = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 23, 59, 59));

        return {
            start: startOfDay.getTime() / 1000,
            end: endOfDay.getTime() / 1000
        };
    }, [selectedDate]);
    const loadCalendarEvents = async () => {
        if (!user) {
            return
        }
        setIsLoadingGoogleCalendarEvents(true);
        await GetCalendarEventsFetch({
            userId: user.id,
            timeMin: startTimeMilliseconds,
            timeMax: endTimeMilliseconds,
        }).then(async (res: CalendarEventsAxiosResponseSuccessType) => {
            setListOfGoogleCalendarEvents(res.data)
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
        });
        setIsLoadingGoogleCalendarEvents(false);
    };
    const loadActivitiesDetailsEvents = useCallback(async () => {
        if (!user) {
            return
        }
        setIsFetchingRecentActivities(true);
        await GetActivitiesDetailsFetch({
            userId: user.id,
        }).then(async (res: ActivitiesDetailsAxiosResponseSuccessType) => {
            const nodeIds = res.data.activities.map((activity) => activity.node.id)
            const importantUsers = await fetchImportantUsers(nodeIds);
            const results = res.data.activities.map((activity) => {
                const importantInfo = importantUsers.find(info => info.nodeId === activity.node.id)!
                const actors = activity.relationships.actors.filter(el => el.userId===user.id).map((actor) => {
                    return {
                        ...actor,
                        user: users.find((user) => user.id === actor.userId)
                    }
                });
                const lastActor = findLastActor(actors);
                return {
                    node: activity.node,
                    messages: activity.message || [],
                    relationships: {
                        ...activity.relationships,
                        actors: activity.relationships.actors.filter(el => el.userId===user.id).map((actor) => {
                            return {
                                ...actor,
                                user: users.find((user) => user.id === actor.userId)
                            }
                        })
                    },
                    importantUserIds: importantInfo.userIds,
                    lastActor: lastActor
                }
            });
            setListOfActivitiesDetailsWithUser(results);
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
        });
        setIsFetchingRecentActivities(false);
    }, [user, users]);
    const loadNoteApplication = useCallback(async (): Promise<number | null> => {
        return await GetNodesApplicationsFetch({
            name: 'Sense Note',
        }).then(async (res: NodesApplicationsAxiosResponseSuccessType) => {
            const note = res.data.find((app) => app.name === 'Sense Note');
            return note?.id || null;
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
            return null;
        });
    }, []);
    const loadRecentActivitiesEmails = useCallback(async () => {
        if (!user) {
            return
        }
        setIsFetchingEmailRecentActivities(true);
        await GetNodeRecentActivitiesFetch({
            userId: user.id,
            nodeType: NodeTypeType.EMAIL,
            users: selectedUsers,
        }).then(async (resRecentActivities: NodeRecentActivitiesAxiosResponseSuccessType) => {
            const listOfRelationships = await fetchNodeRelations(users, resRecentActivities.data.map((node) => node.id));
            const nodeIds = resRecentActivities.data.map((el) => el.id)
            const importantUsers = await fetchImportantUsers(nodeIds);
            const wrappers = resRecentActivities.data.map((node) => {
                const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
                return {
                    node,
                    relationships: listOfRelationships.find((relationship) => relationship.nodeId === node.id),
                    importantUserIds: importantInfo.userIds
                }
            });
            setListOfEmailResentActivities(wrappers);
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
        });
        setIsFetchingEmailRecentActivities(false);
    }, [selectedUsers, user, users]);
    const loadRecentActivitiesNotes = useCallback(async () => {
        if (!user) {
            return
        }
        setIsFetchingNoteRecentActivities(true);
        const noteApplicationId = await loadNoteApplication();
        if (noteApplicationId) {
            await GetActivitiesDetailsFetch({
                userId: user.id,
                apps: [noteApplicationId]
            }).then(async (res: ActivitiesDetailsAxiosResponseSuccessType) => {
                const nodeIds = res.data.activities.map((activity) => activity.node.id)
                const importantUsers = await fetchImportantUsers(nodeIds);
                const results = res.data.activities.map((activity) => {
                    const importantInfo = importantUsers.find(info => info.nodeId === activity.node.id)!
                    const actors = activity.relationships.actors.filter(el => el.userId===user.id).map((actor) => {
                        return {
                            ...actor,
                            user: users.find((user) => user.id === actor.userId)
                        }
                    });
                    const lastActor = findLastActor(actors);
                    return {
                        node: activity.node,
                        messages: activity.message || [],
                        relationships: {
                            ...activity.relationships,
                            actors: activity.relationships.actors.filter(el => el.userId===user.id).map((actor) => {
                                return {
                                    ...actor,
                                    user: users.find((user) => user.id === actor.userId)
                                }
                            })
                        },
                        importantUserIds: importantInfo.userIds,
                        lastActor: lastActor
                    }
                });
                setListOfNoteResentActivities(results);
            }).catch(() => {
                ToastService.showToast('error', 'Some error occurred!');
            });
        }
        setIsFetchingRecentActivities(false);
        // await GetNodeRecentActivitiesFetch({
        //     userId: user.id,
        //     nodeType: NodeTypeType.NOTE,
        //     users: selectedUsers,
        // }).then(async (resRecentActivities: NodeRecentActivitiesAxiosResponseSuccessType) => {
        //     const listOfRelationships = await fetchNodeRelations(users, resRecentActivities.data.map((node) => node.id));
        //     const nodeIds = resRecentActivities.data.map((el) => el.id)
        //     const importantUsers = await fetchImportantUsers(nodeIds);
        //     const wrappers = resRecentActivities.data.map((node) => {
        //         const importantInfo = importantUsers.find(info => info.nodeId === node.id)!
        //         return {
        //             node,
        //             relationships: listOfRelationships.find((relationship) => relationship.nodeId === node.id),
        //             importantUserIds: importantInfo.userIds
        //         }
        //     });
        //     setListOfNoteResentActivities(wrappers);
        // }).catch(() => {
        //     ToastService.showToast('error', 'Some error occurred!');
        // });
        setIsFetchingNoteRecentActivities(false);
    }, [user, users, loadNoteApplication]);
    
    const loadImportantNodeNodes = useCallback(async () => {
      if (!user) return;
      
      setIsFetchingImportant(true);
      
      try {
        const res: NodesAxiosResponseSuccessType = await GetNodesFetch({
          userId: user.id,
          relationType: 'FOLLOWED_BY',
        });
    
        setIsLoading(true);
    
        const nodeIds = res.data.map((node) => node.id);
        const [listOfRelationships, importantUsers] = await Promise.all([
          fetchNodeRelations(users, nodeIds),
          fetchImportantUsers(nodeIds)
        ]);
    
        const importantNodesWithRelationships = res.data.map((node) => {
          const relationships = listOfRelationships.find((relationship) => relationship.nodeId === node.id)!;
          const importantInfo = importantUsers.find(info => info.nodeId === node.id)!;
          
          return {
            node,
            relationships: {
              ...relationships,
              actors: relationships.actors.filter((actor) => actor.actionType !== 'FOLLOWED_BY')
            },
            importantUserIds: importantInfo.userIds
          };
        });
    
        setListOfImportantWithRelationships(importantNodesWithRelationships);
      } catch (error) {
        console.error('Error loading important nodes:', error);
        ToastService.showToast('error', 'Failed to load important nodes. Please try again later.');
      } finally {
        setIsLoading(false);
        setIsFetchingImportant(false);
      }
    }, [user, users]);

    const updateHandler = useCallback(async () => {
        setIsLoading(true)
        loadActivitiesDetailsEvents();
        loadListOfMentions();
        loadRecentActivitiesEmails();
        loadRecentActivitiesNotes();
        loadImportantNodeNodes();
        setIsLoading(false)
    }, [loadActivitiesDetailsEvents, loadListOfMentions, loadRecentActivitiesEmails, loadRecentActivitiesNotes, loadImportantNodeNodes])
    useEffect(() => {
        loadActivitiesDetailsEvents();
    }, [loadActivitiesDetailsEvents]);
    useEffect(() => {
        loadListOfMentions();
        loadRecentActivitiesEmails();
        loadRecentActivitiesNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        loadImportantNodeNodes();
    }, [loadImportantNodeNodes, followedNodes]);
    useEffect(() => {
        activeTab === 'MENTIONS' && loadListOfMentions();
        activeTab === 'EMAILS' && loadRecentActivitiesEmails();
        activeTab === 'NOTES' && loadRecentActivitiesNotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUsers, activeTab]);
    useEffect(() => {
        loadCalendarEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTimeMilliseconds, endTimeMilliseconds]);
    useEffect(() => {
        const handleUpdate = () => {
            updateHandler();
        };
        window.addEventListener('updateDataByNodeDrawer', handleUpdate);
        return () => {
            window.removeEventListener('updateDataByNodeDrawer', handleUpdate);
        };
      }, [updateHandler]);
    return (
        <>
            <ForYouPageContext.Provider value={{
                update: updateHandler,
                listOfMentions,
                dailyCalendarEvents,
                notDailyCalendarEvents,
                listOfActivitiesDetailsWithUser,
                listOfEmailResentActivities,
                listOfNoteResentActivities,
                listOfImportant: listOfImportantWithRelationships,

                activeTab, setActiveTab,

                isLoading,
                isLoadingGoogleCalendarEvents,

                selectedDate, setSelectedDate,
                selectedUsers, setSelectedUsers,
                isFetchingRecentActivities,
                isFetchingEmailRecentActivities,
                isFetchingNoteRecentActivities,
                isFetchingImportant
            }}>
                {children}
            </ForYouPageContext.Provider>
        </>
    );
};