import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    border-radius: 999px;
    background: rgba(0, 0, 0, 0.06);
    height: 8px;
    position: relative;
    margin-top: 8px;
`;
export const StyledLine = styled('div')<{width: number}>`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #636363;
    width: ${({ width }) => `${width}%`};
    border-radius: 999px;
    transition: width 0.5s;
`;